import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import * as Yup from 'yup';

import downloadCenter from '@/apiConfigs/downloadCenter';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Modal from '@/designComponents/Modal';
import Typography from '@/designComponents/Typography';
import { InfoRowStyled, NewDocumentWrapper } from '@/pages/Documents/style';
import requestAPI from '@/utils/requestAPI';

import { BottomContainerStyled, ModalContainerStyled } from '../../style';

export type InitialValuesType = {
  name: string;
};

type Props = {
  isOpenModal: boolean;
  onClose: () => void;
};
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

const CreateFolders = ({ isOpenModal, onClose }: Props) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { folderId } = useParams();

  const fetchFolderData = async () => {
    if (folderId) {
      const response = await requestAPI(
        downloadCenter.getSingleDownloadCenterGroup(folderId)
      );
      return response;
    }
    return { title: '' };
  };

  const handleFormSubmit = async (val: InitialValuesType) => {
    setLoading(true);
    try {
      if (folderId) {
        await requestAPI(
          downloadCenter.updateDownloadCenterGroup(folderId, {
            title: val.name,
          })
        );
        toast.success('Folder updated successfully');
      } else {
        await requestAPI(
          downloadCenter.addDownloadCenterGroup({
            title: val.name,
          })
        );
        toast.success('Folder created successfully');
      }
      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalContainerStyled>
      <Modal
        isOpen={isOpenModal}
        onClose={onClose}
        title={folderId ? 'Update Folder' : 'Create New Folder'}
        halfWidth={true}
      >
        <Formik
          initialValues={{
            name: '',
          }}
          enableReinitialize
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, handleSubmit, errors, touched }) => {
            useEffect(() => {
              const populateForm = async () => {
                if (folderId) {
                  const folderData = await fetchFolderData();
                  setFieldValue('name', folderData.title || '');
                }
              };
              populateForm();
            }, [folderId, setFieldValue]);

            return (
              <Form onSubmit={handleSubmit}>
                <NewDocumentWrapper>
                  <InfoRowStyled>
                    <Typography
                      fontFamily="roboto"
                      size="normal"
                      fontWeight="medium"
                      color="dark.neutral_45"
                    >
                      Name <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Input
                      name="name"
                      value={values.name}
                      onChange={(e) => setFieldValue('name', e.target.value)}
                    />
                    {errors.name && touched.name && (
                      <div style={{ color: 'red' }}>{errors.name}</div>
                    )}
                  </InfoRowStyled>
                </NewDocumentWrapper>
                <BottomContainerStyled>
                  <Button
                    radius="md"
                    variant="ghost"
                    style={{
                      border: `1px solid ${theme.colors.dark.neutral_250}`,
                      borderRadius: '10px',
                      color: theme.colors.text,
                      fontFamily: theme.fontFamily.roboto,
                      fontWeight: theme.fontWeights.medium,
                    }}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    radius="normal"
                    isLoading={loading}
                    style={{ backgroundColor: theme.colors.brandColorBlue }}
                  >
                    {folderId ? 'Update' : 'Create'}
                  </Button>
                </BottomContainerStyled>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </ModalContainerStyled>
  );
};

export default CreateFolders;
