import { Form, Formik } from 'formik';
import { useState } from 'react';
import { PiTreeStructure } from 'react-icons/pi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import avatar from '@/assets/avatar.png';
import MasterFlagSVG from '@/assets/country-flag.svg';
import IrelandFlagSVG from '@/assets/Ireland.svg';
import { ReactComponent as LogoSVG } from '@/assets/logo.svg';
import UKFlagSVG from '@/assets/united-kingdom.svg';
import { useRoot } from '@/context/RootProvider';
import Select from '@/designComponents/Select';
import Typography from '@/designComponents/Typography';
import DocumentTree from '@/pages/Documents/DocumentTree';
import { removeAuth } from '@/store/auth';
import { clearAxiosConfig, setUsersToLocalStorage } from '@/utils/apiHelpers';

import { AvatarDropdownStyled, PrivateHeaderStyled, Wrapper } from './styles'; // Assuming you have styles for the dropdown

const PrivateHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { headerTitle } = useRoot();

  const [openDocumentTreeModal, setOpenDocumentTreeModal] = useState(false);

  const { region, setRegion } = useRoot();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const dropDownOption = [
    { label: 'Master Dashboard', value: 'master', logo: MasterFlagSVG },
    { label: 'UK', value: 'uk', logo: UKFlagSVG },
    { label: 'Ireland', value: 'ie', logo: IrelandFlagSVG },
  ];

  const avatarDropdown = [
    { label: 'Profile', url: '/profile' },
    {
      label: 'Logout',
      onPress: () => {
        dispatch(removeAuth());
        setUsersToLocalStorage('');
        clearAxiosConfig();
        navigate('/login');
      },
    },
  ];

  const handleAvatarClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleDropdownOptionClick = (item: any) => {
    if (item.url) {
      navigate(item.url);
    }
    if (item.onPress) {
      item.onPress();
    }
    setDropdownVisible(false);
  };

  const onClose = () => {
    setOpenDocumentTreeModal(false);
  };

  return (
    <>
      <Wrapper>
        <div className="line" />
        <PrivateHeaderStyled>
          <div className="logo-with-content">
            <LogoSVG
              onClick={() => navigate('/')}
              style={{ cursor: 'pointer', height: 20 }}
            />
            {headerTitle && (
              <div className="content">
                {typeof headerTitle === 'string' ? (
                  <Typography
                    size="highlight"
                    color="dark.neutral_450"
                    fontWeight="medium"
                  >
                    {headerTitle}
                  </Typography>
                ) : (
                  <>{headerTitle}</>
                )}
              </div>
            )}
          </div>
          <div className="content-right">
            <PiTreeStructure
              size={20}
              role="button"
              onClick={() => setOpenDocumentTreeModal(true)}
              style={{
                cursor: 'pointer',
              }}
            />
            <div className="flag-content">
              <Formik initialValues={{ country: 'master' }} onSubmit={() => {}}>
                {(formikProps) => (
                  <Form>
                    <img
                      src={
                        dropDownOption.find(
                          (item) => formikProps.values.country === item.value
                        )?.logo
                      }
                      alt="country-flag"
                    />
                    <Select
                      options={dropDownOption}
                      name="country"
                      placeholder="Select Country"
                      isHideBorder
                      formikProps={formikProps}
                      isReduceIconGap
                      selected={region}
                      onSelect={(val) => {
                        if (val === 'master') {
                          setRegion(undefined);
                        } else {
                          setRegion(val);
                        }
                      }}
                    />
                  </Form>
                )}
              </Formik>
            </div>
            <div className="avatar-container" onClick={handleAvatarClick}>
              <img src={avatar} alt="avatar" />
              {dropdownVisible && (
                <AvatarDropdownStyled>
                  {avatarDropdown.map((item) => (
                    <div
                      key={item.label}
                      onClick={() => handleDropdownOptionClick(item)}
                    >
                      {item.label}
                    </div>
                  ))}
                </AvatarDropdownStyled>
              )}
            </div>
          </div>
        </PrivateHeaderStyled>
      </Wrapper>

      {openDocumentTreeModal && (
        <DocumentTree isOpenModal={openDocumentTreeModal} onClose={onClose} />
      )}
    </>
  );
};

export default PrivateHeader;
