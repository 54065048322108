import axios from 'axios';

import configs from '@/configs';
import { localStorageKeys } from '@/constants';
import { AuthType } from '@/types/auth';

// Create an axios instance
const axiosInstance = axios.create({
  baseURL: configs.apiURL,
});

const accessToken = localStorage.getItem(localStorageKeys.accessToken);
if (accessToken) {
  axiosInstance.defaults.headers.Authorization = `Bearer ${accessToken}`;
}

export const clearAxiosConfig = () => {
  delete axiosInstance.defaults.headers.Authorization;
  delete axiosInstance.defaults.headers.common.Authorization;
};

export const setAuthorizationHeader = (token: string) => {
  axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
  localStorage.setItem(localStorageKeys.accessToken, token);
};

export const setRegionHeader = (region?: string) => {
  axiosInstance.defaults.headers.Region = region;
  axiosInstance.defaults.headers.common.Region = region;
};

export const setAxiosInstanceBaseURL = (baseURL: string) => {
  axiosInstance.defaults.baseURL = baseURL;
};

export const setUsersToLocalStorage = (user: AuthType) => {
  localStorage.setItem(localStorageKeys.user, JSON.stringify(user));
};

export const getAxiosInstance = () => {
  return axiosInstance;
};

export default axiosInstance;
