import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PaginationType } from '@/apiConfigs/downloadCenter';
import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import { Table } from '@/components';
import Pagination from '@/components/Pagination';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import { SearchInputWrapperStyled } from '@/pages/Documents/style';
import { RootState, useAppDispatch } from '@/store';
import { getAllDownloadResources } from '@/store/downloadCenter/functions';
import { humanReadableDateWithTime } from '@/utils/date';
import { debounce } from '@/utils/debounce';

const DownloadResource = ({ downloadCenterTabs, search }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [paginationParams, setPaginationParams] = useState<PaginationType>({
    page: 1,
    limit: 10,
    search: '',
  });
  const resources = useSelector(
    (state: RootState) => state.downloadCenterCategory.downloadCenterResource
  );

  const pagination = useSelector(
    (state: RootState) => state.downloadCenterCategory.pagination
  );

  const fetchDownloadFolders = useCallback(() => {
    dispatch(getAllDownloadResources(paginationParams));
  }, [dispatch, paginationParams]);
  // useEffect(() => {
  //   dispatch(getAllDownloadResources({ limit: 10, page: 1 }));
  // }, []);
  useEffect(() => {
    fetchDownloadFolders();
  }, [fetchDownloadFolders]);

  const tableData = {
    headings: [
      // { key: 'id', label: 'Id' },

      { key: 'title', label: 'Title' },
      { key: 'type', label: 'Type' },
      { key: 'date', label: 'Created At' },
      { key: 'updatedDate', label: 'Updated At' },
      { key: 'actions', label: 'Actions' },
    ],
    body: Array.isArray(resources)
      ? resources?.map((resource) => {
          return {
            id: resource._id,

            title: resource.title,
            type: resource.type,
            date: humanReadableDateWithTime(resource.createdAt),
            updatedDate: humanReadableDateWithTime(resource.updatedAt),
            actions: (
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="gray"
                  radius="normal"
                  onClick={() => navigate(resource._id)}
                >
                  View
                </Button>
                {/* <Button variant="danger" radius="normal" onClick={() => {}}>
                  Delete
                </Button> */}
              </div>
            ),
          };
        })
      : [],
  };

  const handleCurrentPage = (page: number) => {
    setPaginationParams((prev) => ({ ...prev, page: page }));
  };

  const handleLimitChange = (limit: number) => {
    setPaginationParams((prev) => ({ ...prev, page: 1, limit }));
  };

  const handleNextPage = () => {
    if (paginationParams.page < pagination?.totalPages) {
      handleCurrentPage(paginationParams.page + 1);
    }
  };
  const handleLastPage = () => {
    if (paginationParams.page !== pagination?.totalPages) {
      handleCurrentPage(pagination.totalPages);
    }
  };

  const debouncedSearch = useCallback(
    debounce((term: string) => {
      setPaginationParams((prev) => ({
        ...prev,
        search: term,
        page: 1,
      }));
    }, 300),
    []
  );
  useEffect(() => {
    if (search) {
      debouncedSearch(search);
    }
  }, [search]);

  return (
    <div>
      <Table
        tableData={tableData}
        customRow={downloadCenterTabs}
        // isLoading={isLoading}
        noData="No data to display."
        headingBgColor="#f0f0f0"
        showMenuIcon={false}
        evenRowBgColor="#fafafa"
      />

      <Pagination
        activePage={paginationParams.page || 1}
        onCurrentPageChange={handleCurrentPage}
        onLast={handleLastPage}
        onNext={handleNextPage}
        totalPages={paginationParams?.totalPages}
        limit={paginationParams.limit || 10}
        onLimitChange={handleLimitChange}
      />
    </div>
  );
};

export default DownloadResource;
