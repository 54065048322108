import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
// import { FaRegCalendarAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { paginationType } from '@/apiConfigs/users';
import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import { Table } from '@/components';
import Pagination from '@/components/Pagination';
import { userAnalyticsKeys } from '@/constants';
import { useRoot } from '@/context/RootProvider';
import Button from '@/designComponents/Button';
import { ButtonTab } from '@/designComponents/ButtonTab';
import Input from '@/designComponents/Input';
import { TableDataType } from '@/designComponents/Table';
import Typography from '@/designComponents/Typography';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import { RootState, useAppDispatch } from '@/store';
import { getAllUsersWithAnalytics } from '@/store/users/functions';
import { toCapitalCase } from '@/utils/conversion';
import { debounce } from '@/utils/debounce';

import { AccountWrapperStyled, SearchInputWrapperStyled } from './style';

const Accounts = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { region } = useRoot();
  const [cardKey, setCardKey] = useState<string | null>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [startDate, endDate] = dateRange;
  const [pageParams, setPageParams] = useState<paginationType>({
    page: 1,
    limit: 10,
    search: '',
    startDate: '',
    endDate: '',
  });
  const usersState = useSelector((state: RootState) => state.users);
  const { users, pagination, analytics } = usersState;
  const fetchUsers = (myParams: paginationType) => {
    const noEmptyParams = Object.entries(myParams).reduce((acc, val) => {
      if (!val[1]) {
        return acc;
      }
      acc = { ...acc, [val[0]]: val[1] };
      return acc;
    }, {});
    dispatch(getAllUsersWithAnalytics(noEmptyParams));
  };

  const delayedFetch = useCallback(debounce(fetchUsers, 300), []);

  useEffect(() => {
    fetchUsers(pageParams);
  }, [dispatch, region]);

  const tableData: TableDataType = {
    headings: [
      { key: 'name', label: 'Full name' },
      { key: 'email', label: 'Email' },
      { key: 'loginCount', label: 'Login count' },
      { key: 'date', label: 'Registered at' },
      { key: 'account', label: 'Account' },
      { key: 'actions', label: '' },
    ],
    body: Array.isArray(users)
      ? users.map((user) => ({
          id: user._id,
          name: <Link to={`/users/${user._id}`}>{user.fullName}</Link>,
          email: user.email,
          loginCount: user.loginCount,
          account: user.accountType,
          date: moment(user.createdAt).format('MMM DD, YYYY  HH:mm'),
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="gray"
                radius="normal"
                size="md"
                onClick={() => navigate(`/users/${user._id}`)}
                style={{ margin: 10 }}
              >
                View
              </Button>
            </div>
          ),
        }))
      : [],
  };

  const updateParamsAndFetchUsers = (updatedPagination: paginationType) => {
    setPageParams(updatedPagination);
    delayedFetch(updatedPagination);
  };

  const handleCurrentPage = (page: number) => {
    const updatedPagination = { ...pageParams, page };
    updateParamsAndFetchUsers(updatedPagination);
  };

  const handleLimitChange = (limit: number) => {
    setPageParams((prev) => ({ ...prev, page: 1, limit }));
  };

  const handleNextPage = () => {
    if (
      pageParams.page &&
      pagination?.totalPages &&
      pageParams?.page < pagination.totalPages
    ) {
      handleCurrentPage(pageParams.page + 1);
    }
  };

  const handleLastPage = () => {
    if (pagination?.totalPages && pageParams.page !== pagination.totalPages) {
      handleCurrentPage(pagination.totalPages);
    }
  };

  const onDocumentsTypeChange = (key: string) => {
    setCardKey(key);
    const updatedPagination = { ...pageParams, page: 1, type: key };
    updateParamsAndFetchUsers(updatedPagination);
  };

  const onSearchValueChange = (value: string) => {
    const updatedPagination = { ...pageParams, page: 1, search: value };
    updateParamsAndFetchUsers(updatedPagination);
  };

  const reducedAllCount = Object.values(analytics).reduce(
    (prev, curr: { key: string; value: string }) => (prev += curr.value),
    0
  );

  const cardData = Object.keys(analytics).length
    ? userAnalyticsKeys.map((ak) => ({
        title: toCapitalCase(ak),
        count: ak === 'all' ? reducedAllCount : analytics[ak]?.value || `${0}`,
        key:
          ak === 'individual-users'
            ? 'individual-users'
            : ak === 'professional-users'
              ? 'professional-users'
              : ak === 'advanced-users'
                ? 'advanced-users'
                : ak === 'plus-users'
                  ? 'plus-users'
                  : ak === 'all'
                    ? null
                    : ak,
      }))
    : [];

  const handleDateChange = () => {
    const updatedPagination = {
      ...pageParams,
      startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
      endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
      page: 1,
    };
    updateParamsAndFetchUsers(updatedPagination);
  };

  const handleOverlayClose = () => {
    const updatedPagination = {
      ...pageParams,
      startDate: '',
      endDate: '',
      page: 1,
    };
    updateParamsAndFetchUsers(updatedPagination);
    setIsDatePickerOpen(false);
  };

  const handleClearDate = () => {
    const updatedPagination = {
      ...pageParams,
      startDate: '',
      endDate: '',
      page: 1,
    };
    updateParamsAndFetchUsers(updatedPagination);
  };

  const handleQuickDateFilter = (filterType: string) => {
    let newStartDate, newEndDate;

    switch (filterType) {
      case 'today':
        newStartDate = newEndDate = moment().toDate();
        break;
      case 'this-week':
        newStartDate = moment().startOf('week').toDate();
        newEndDate = moment().endOf('week').toDate();
        break;
      case 'this-month':
        newStartDate = moment().startOf('month').toDate();
        newEndDate = moment().endOf('month').toDate();
        break;
      default:
        newStartDate = newEndDate = null;
    }

    setDateRange([newStartDate, newEndDate]);
    const updatedPagination = {
      ...pageParams,
      startDate: newStartDate ? moment(newStartDate).format('YYYY-MM-DD') : '',
      endDate: newEndDate ? moment(newEndDate).format('YYYY-MM-DD') : '',
      page: 1,
    };
    updateParamsAndFetchUsers(updatedPagination);
  };

  const userTabs = (
    <ButtonTab
      items={cardData}
      activeIndex={cardData.findIndex((x) => x.key === cardKey)}
      setActiveIndex={(index) => onDocumentsTypeChange(cardData[index].key)}
    />
  );

  useHeaderTitle('Users');

  return (
    <AccountWrapperStyled>
      <div style={{ marginBottom: '16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SearchInputWrapperStyled>
            <Input
              value={pageParams.search}
              onChange={(e) => {
                const { value } = e.target;
                onSearchValueChange(value);
              }}
              placeholder="Search Users"
              iconLeft={<SearchSVG />}
            />
          </SearchInputWrapperStyled>
          <div className="main">
            <Button
              onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
              radius="normal"
            >
              Filter by Date
            </Button>
            {isDatePickerOpen && (
              <div className="overlay">
                <div className="overlay-content">
                  <div style={{ display: 'flex', gap: '20px' }}>
                    <DatePicker
                      selected={startDate}
                      onChange={(update) => {
                        setDateRange(update as [Date | null, Date | null]);
                        if (!update) {
                          handleClearDate();
                        }
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      isClearable
                      placeholderText="Select Date Range"
                      dateFormat="yyyy-MM-dd"
                      className="custom-datepicker"
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginRight: '12px',
                        width: '80px',
                      }}
                    >
                      <Typography
                        onClick={() => handleQuickDateFilter('today')}
                        style={{ cursor: 'pointer' }}
                      >
                        Today
                      </Typography>
                      <Typography
                        onClick={() => handleQuickDateFilter('this-week')}
                        style={{ cursor: 'pointer' }}
                      >
                        This Week
                      </Typography>
                      <Typography
                        onClick={() => handleQuickDateFilter('this-month')}
                        style={{ cursor: 'pointer' }}
                      >
                        This Month
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: '8px',
                      display: 'flex',
                      gap: '15px',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Button onClick={handleDateChange} radius="normal">
                      Apply
                    </Button>
                    <Button
                      onClick={handleOverlayClose}
                      variant="gray"
                      radius="normal"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Table
        customRow={userTabs}
        tableData={tableData}
        noData="No data to display."
        showCheckBox={false}
        maxWidthForCardView="768px"
        headingBgColor="#f0f0f0"
        showMenuIcon={false}
        evenRowBgColor="#fafafa"
      />
      <Pagination
        activePage={pageParams.page || 1}
        onCurrentPageChange={handleCurrentPage}
        totalPages={pagination?.totalPages || 1}
        limit={pageParams.limit || 10}
        onLimitChange={handleLimitChange}
        onNext={handleNextPage}
        onLast={handleLastPage}
      />
    </AccountWrapperStyled>
  );
};

export default Accounts;
