import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import forms from '@/apiConfigs/forms';
import Typography from '@/designComponents/Typography';
import requestAPI from '@/utils/requestAPI';

type SingleFormType = {
  firstName: string;
  lastName: string;
  email: string;
  mortgageType: string;
  mortgageUsages: string;
  phone: string;
  type: string;
  preferredContactTime: string;
};

export const InformationRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .text {
    display: grid;
    grid-template-columns: 90px 1fr;
    align-items: center;
    gap: 32px;
  }
  .value {
    font-family: roboto;
    font-weight: 400;
    color: #4c4c4c;
    size: 14px;
  }
`;

const FormDetails = () => {
  const { formId } = useParams();
  const [singleForm, setSingleForm] = useState<SingleFormType>({});

  const getSingleForm = async (id: string) => {
    const data = await requestAPI(forms.getSingleForm(id));
    setSingleForm(data);
  };

  useEffect(() => {
    if (formId) {
      getSingleForm(formId);
    }
  }, [formId]);

  return (
    <InformationRowWrapper>
      <>
        <div className="text">
          <Typography className="value">Full Name</Typography>
          <Typography className="value">
            {singleForm?.firstName + '' + singleForm?.lastName}
          </Typography>
        </div>
        <div className="text">
          <Typography className="value">Email</Typography>
          <Typography className="value">{singleForm?.email}</Typography>
        </div>
        <div className="text">
          <Typography className="value">Phone</Typography>
          <Typography className="value">{singleForm?.phone}</Typography>
        </div>
        <div className="text">
          <Typography className="value">Type</Typography>
          <div style={{ display: 'flex', gap: '13px', alignItems: 'center' }}>
            <Typography className="value">{singleForm?.type}</Typography>
          </div>
        </div>
        <div className="text">
          <Typography className="value">Preferred Contact Time</Typography>
          <Typography className="value">
            {singleForm?.preferredContactTime}
          </Typography>
        </div>
      </>
    </InformationRowWrapper>
  );
};

export default FormDetails;
