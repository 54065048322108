import { Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

import documents from '@/apiConfigs/documents';
import CountrySelection from '@/components/CountrySelection';
import TextEditor from '@/components/TextEditor';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Modal from '@/designComponents/Modal';
import { ModalContentWrapper } from '@/designComponents/Modal/style';
import Select from '@/designComponents/Select';
import Typography from '@/designComponents/Typography';
import UploadFile from '@/designComponents/UploadFile';
import { RootState, useAppDispatch, useAppSelector } from '@/store';
import {
  getAllDocumentWithAnalytics,
  getAllGroupDocument,
  getSingleDocument,
  getSingleDocumentParent,
} from '@/store/documents/functions';
import { getHashTags } from '@/store/hashTags/function';
import requestAPI from '@/utils/requestAPI';
import { uploadFilesToS3OnlyKeys } from '@/utils/s3Upload';

import {
  generateSlug,
  showRespectiveHashtag,
  switchBodyContent,
} from '../function';
import { InfoRowStyled, NewDocumentWrapper } from '../style';
import { createDocValidationSchema } from '../validationSchema';

type Props = {
  isOpenModal: boolean;
  onClose: () => void;
  documentId?: string;
  documentGroupId?: string;
  documentGroupTitle?: string;
  type?: string;
  countryRegion?: string;
  status?: string;
};

export type InitialValuesType = {
  title: string;
  documentGroupId: string;
  hashTags: Array<{ label: string; value: string }>;
  type: string;
  editorHtml: string;
  pdf?: File | null | string;
  icon?: File | null | string;
  slug?: string;
  position: number;
  status: string;
  parentId: string[];
  region: string;
};

const sanitizeSlug = (slug: string) => {
  return slug.replace(/\?/g, '');
};
const CreateDocuments = ({
  isOpenModal,
  onClose,
  documentId,
  documentGroupId,
  documentGroupTitle,
  type = '',
  countryRegion,
  status,
}: Props) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { singleDocument, parentList } = useAppSelector(
    (state: RootState) => state.documents
  );
  const hashTagData = useAppSelector((state: RootState) => state.hashTags);
  const [parentsList, setParentList] = useState([]);
  const [apiError, setApiError] = useState('');
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<InitialValuesType>({
    title: '',
    documentGroupId: documentGroupId || '',
    hashTags: [],
    type: type,
    editorHtml: '',
    icon: null,
    pdf: null,
    position: 1,
    status: status || '',
    parentId: [],
    region: countryRegion || 'ie',
    slug: '',
  });

  const [selectedType, setSelectedType] = useState('text');

  useEffect(() => {
    dispatch(
      getHashTags({
        limit: 100,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (documentId && singleDocument) {
      const contentType = singleDocument.body?.contentType;
      switch (contentType) {
        case 'html':
          setSelectedType('text');
          break;
        case 'pdf':
          setSelectedType('pdf');
          break;
        case 'image':
          setSelectedType('icon');
          break;
        default:
          setSelectedType('text');
      }
    } else {
      setSelectedType('text');
    }
  }, [documentId, singleDocument]);

  const hashTags =
    hashTagData.hashTags &&
    hashTagData.hashTags.length &&
    hashTagData?.hashTags?.map((tag) => ({
      label: tag.tag,
      value: tag._id,
    }));

  console.log(singleDocument?.hashTags, 'hashtags single document');

  useEffect(() => {
    if (documentId && singleDocument && parentList) {
      setInitialValues({
        documentGroupId: singleDocument?.documentGroupId,
        editorHtml:
          singleDocument.body && switchBodyContent('html', singleDocument.body),
        // hashTags: showRespectiveHashtag(singleDocument?.hashTags, hashTags),
        hashTags: showRespectiveHashtag(
          singleDocument?.hashTags?.map((tag) => tag._id),
          hashTags
        ),
        position: singleDocument?.position,
        region: singleDocument?.region?.[0],
        status: singleDocument?.status,
        title: singleDocument?.title,
        type: singleDocument?.type,
        parentId: parentList?.parentIds,
        slug: singleDocument?.slug,
        icon:
          singleDocument.body &&
          switchBodyContent('image', singleDocument.body),
        pdf:
          singleDocument.body && switchBodyContent('pdf', singleDocument.body),
      });
      setParentList(parentList.parentList);
    }
  }, [documentId, singleDocument, parentList]);

  const formikRef = useRef<FormikProps<InitialValuesType>>(null);

  useEffect(() => {
    if (documentId) {
      dispatch(getSingleDocumentParent(documentId));
    }
  }, [documentId, dispatch]);

  const handleFormSubmit = async (val: InitialValuesType) => {
    let content: any = '';
    let contentType: any = '';
    if (selectedType === 'text') {
      content = val.editorHtml;
      contentType = 'html';
    } else if (selectedType === 'pdf' && val.pdf) {
      const pdfKeys = await uploadFilesToS3OnlyKeys([val.pdf]);
      if (pdfKeys && pdfKeys.length > 0) {
        content = pdfKeys[0];
        contentType = 'pdf';
      } else {
        toast.error('Failed to upload PDF');
        return;
      }
    } else if (selectedType === 'icon' && val.icon) {
      const imageKeys = await uploadFilesToS3OnlyKeys([val.icon]);
      if (imageKeys && imageKeys.length > 0) {
        content = imageKeys[0];
        contentType = 'image';
      } else {
        toast.error('Failed to upload image');
        return;
      }
    }
    const lastId =
      documentGroupId || JSON.parse(JSON.stringify(val.parentId)).pop();

    try {
      setLoading(true);

      await requestAPI(
        documents.addDocument({
          title: val.title,
          documentGroupId: lastId,
          region: [val.region],
          slug: val.slug,
          body: {
            content: content,
            contentType,
          },
          type:
            parentsList
              ?.flatMap((parent) => parent)
              .find(
                (parent) =>
                  parent?._id === formikRef?.current?.values?.parentId?.[0]
              )?.type || type,
          hashTags: val.hashTags.map((tag: any) => tag.value),
          position: val.position,
          status: val.status,
        })
      );
      setLoading(false);
      dispatch(getAllDocumentWithAnalytics({}));
      onClose();
      toast.success('Document created successfully');
    } catch (e: any) {
      setLoading(false);
      setApiError(e.message);
      toast.error(e.message);
    }
  };

  useEffect(() => {
    if (documentId) {
      dispatch(getSingleDocument(documentId));
    }
    if (documentId) {
      dispatch(getSingleDocument(documentId));
    }
  }, [dispatch, documentId]);

  const handleUpdateDocument = async (val: InitialValuesType) => {
    let content: string = '';
    let contentType: string = '';
    if (selectedType === 'text') {
      content = val.editorHtml;
      contentType = 'html';
    } else if (selectedType === 'pdf' && val.pdf) {
      if (typeof val.pdf !== 'string') {
        const pdfKeys = await uploadFilesToS3OnlyKeys([val.pdf]);
        if (pdfKeys && pdfKeys.length > 0) {
          content = pdfKeys[0];
          contentType = 'pdf';
        } else {
          toast.error('Failed to upload PDF');
          return;
        }
      } else {
        content = val.pdf;
        contentType = 'pdf';
      }
    } else if (selectedType === 'icon' && val.icon) {
      if (typeof val.icon !== 'string') {
        const imageKeys = await uploadFilesToS3OnlyKeys([val.icon]);
        if (imageKeys && imageKeys.length > 0) {
          content = imageKeys[0];
          contentType = 'image';
        } else {
          toast.error('Failed to upload image');
          return;
        }
      } else {
        content = val.icon;
        contentType = 'image';
      }
    }
    const lastId =
      documentGroupId || JSON.parse(JSON.stringify(val.parentId)).pop();
    try {
      setLoading(true);

      await requestAPI(
        documents.updateDocument(documentId, {
          title: val.title,
          documentGroupId: lastId,
          region: [val.region],
          slug: val.slug,
          body: {
            content,
            contentType,
          },
          type:
            parentsList
              ?.flatMap((parent) => parent)
              .find(
                (parent) =>
                  parent?._id === formikRef?.current?.values?.parentId?.[0]
              )?.type || type,
          hashTags: val.hashTags.map((tag: any) => tag.value),
          position: val.position,
          status: val.status,
        })
      );
      setLoading(false);
      dispatch(getSingleDocument(documentId));
      onClose();
      toast.success('Document updated successfully');
    } catch (error: any) {
      setLoading(false);
      setApiError(error.message);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (isOpenModal) {
      dispatch(getAllGroupDocument({ region: 'ie' }));
    }
  }, [dispatch, isOpenModal]);

  const fetchDocumentGroups = async (region: string, parentId?: string) => {
    const data = await requestAPI(
      documents.getAllDocumentGroup({
        parentId,
        region: region,
      })
    );

    return data;
  };

  const loadInitial = async () => {
    const data = await fetchDocumentGroups('ie');

    setParentList([data]);
  };

  useEffect(() => {
    loadInitial();
  }, []);

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        onClose={onClose}
        title={documentId ? 'Edit Document' : 'Create New Document'}
      >
        <ModalContentWrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={() => createDocValidationSchema(selectedType)}
            innerRef={formikRef}
            onSubmit={documentId ? handleUpdateDocument : handleFormSubmit}
            enableReinitialize
          >
            {(formikProps) => {
              const { values, errors, touched, setFieldValue } = formikProps;
              // console.log({ editor: values.editorHtml });

              const handleTitleChange = (
                e: React.ChangeEvent<HTMLInputElement>
              ) => {
                const { value } = e.target;
                setFieldValue('title', value);
                if (value) {
                  let generatedSlug = generateSlug(value);
                  generatedSlug = sanitizeSlug(generatedSlug);
                  setFieldValue('slug', generatedSlug);
                }
              };
              const handleSlugChange = (
                e: React.ChangeEvent<HTMLInputElement>
              ) => {
                const { value } = e.target;
                const sanitizedSlug = sanitizeSlug(value);
                setFieldValue('slug', sanitizedSlug);
              };

              return (
                <Form>
                  <NewDocumentWrapper>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <CountrySelection
                        selectedCountry={values.region}
                        setSelectedCountry={async (val) => {
                          setFieldValue('region', val);
                          const data = await fetchDocumentGroups('ie');
                          setParentList([data]);
                          setFieldValue('parentId', []);
                        }}
                      />
                    </div>
                    <InfoRowStyled>
                      <Typography className="text">
                        Name / Title <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Input
                        name="title"
                        value={values.title}
                        onChange={handleTitleChange}
                      />
                      {errors.title && touched.title && (
                        <div style={{ color: 'red' }}>{errors.title}</div>
                      )}
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography className="text">
                        Slug <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Input
                        name="slug"
                        value={values.slug}
                        onChange={handleSlugChange}
                      />
                    </InfoRowStyled>

                    <InfoRowStyled>
                      <Typography fontWeight="medium">Parent Group</Typography>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          flex: 1,
                          width: '100%',
                        }}
                      >
                        {documentGroupTitle ? (
                          <Typography className="text">
                            Parent: {documentGroupTitle}
                          </Typography>
                        ) : (
                          parentsList?.map?.((parent, index) => (
                            <Select
                              key={parent || index}
                              name="parentId"
                              placeholder="Select Parent Group"
                              options={[
                                {
                                  label: '',
                                  value: '',
                                },
                                ...(parentsList?.[index]?.map?.(
                                  (group: any) => ({
                                    label: group.title,
                                    value: group._id,
                                  })
                                ) || []),
                              ]}
                              style={{
                                width: '100px !important',
                                maxWidth: '200px !important',
                              }}
                              selected={
                                values.parentId && values?.parentId[index]
                              }
                              onSelect={async (value) => {
                                if (!value) {
                                  return;
                                }

                                const childData = await fetchDocumentGroups(
                                  values.region,
                                  String(value)
                                );
                                setParentList((prev) =>
                                  [
                                    ...prev.slice(0, index + 1),
                                    childData.length ? childData : undefined,
                                  ].filter(Boolean)
                                );
                                setFieldValue('parentId', [
                                  ...values.parentId.slice(0, index),
                                  value,
                                ]);
                              }}
                            />
                          ))
                        )}
                      </div>
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography className="text">
                        Position <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Input
                        name="position"
                        value={values.position}
                        onChange={(e) =>
                          setFieldValue('position', e.target.value)
                        }
                        type="number"
                        min={0}
                      />
                      {errors.position && touched.position && (
                        <div style={{ color: 'red' }}>{errors.position}</div>
                      )}
                    </InfoRowStyled>

                    <InfoRowStyled>
                      <Typography className="text">Hash Tags</Typography>
                      <CreatableSelect
                        className="reactSelect"
                        isMulti
                        name="hashTags"
                        options={hashTags}
                        value={values.hashTags}
                        onChange={(selectedOptions) => {
                          setFieldValue('hashTags', selectedOptions);
                        }}
                      />
                      {typeof errors.hashTags === 'string' &&
                        errors.hashTags &&
                        touched.hashTags && (
                          <div style={{ color: 'red' }}>{errors.hashTags}</div>
                        )}
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography className="text">
                        Status <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Select
                        name="status"
                        placeholder="Select  Group"
                        options={[
                          {
                            label: 'Active',
                            value: 'ACTIVE',
                          },
                          {
                            label: 'Draft',
                            value: 'DRAFT',
                          },
                        ]}
                        selected={values.status}
                        onSelect={(value) => setFieldValue('status', value)}
                      />
                      {errors.status && touched.status && (
                        <div style={{ color: 'red' }}>{errors.status}</div>
                      )}
                    </InfoRowStyled>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Button
                        variant={selectedType === 'text' ? 'primary' : 'ghost'}
                        onClick={() => setSelectedType('text')}
                        htmlType="button"
                      >
                        Text
                      </Button>

                      <Button
                        variant={selectedType === 'icon' ? 'primary' : 'ghost'}
                        onClick={() => setSelectedType('icon')}
                        htmlType="button"
                      >
                        Image
                      </Button>
                      <Button
                        variant={selectedType === 'pdf' ? 'primary' : 'ghost'}
                        onClick={() => setSelectedType('pdf')}
                        htmlType="button"
                      >
                        PDF
                      </Button>
                    </div>
                    <InfoRowStyled>
                      <Typography className="text">
                        {selectedType === 'text' && (
                          <>
                            Text <span style={{ color: 'red' }}>*</span>
                          </>
                        )}
                        {selectedType === 'pdf' && (
                          <>
                            Upload PDF <span style={{ color: 'red' }}>*</span>
                          </>
                        )}
                        {selectedType === 'icon' && (
                          <>
                            Upload Image <span style={{ color: 'red' }}>*</span>
                          </>
                        )}
                      </Typography>
                      {selectedType === 'text' && (
                        <>
                          <TextEditor
                            initialHtml={values.editorHtml}
                            setEditorState={({ html }) =>
                              setFieldValue('editorHtml', html)
                            }
                          />
                          {errors.editorHtml && touched.editorHtml && (
                            <div style={{ color: 'red' }}>
                              {errors.editorHtml}
                            </div>
                          )}
                        </>
                      )}
                      {selectedType === 'pdf' && (
                        <>
                          <UploadFile
                            title=""
                            acceptedFiles=".pdf"
                            name="pdf"
                            onChange={(file) => setFieldValue('pdf', file)}
                            variant="previewImage"
                            apiUrls={
                              documentId && typeof values.pdf === 'string'
                                ? [values.pdf]
                                : []
                            }
                            onRemove={() => {
                              setFieldValue('pdf', null);
                            }}
                          />
                          {typeof errors.pdf === 'string' &&
                            errors.pdf &&
                            touched.pdf && (
                              <div style={{ color: 'red' }}>{errors.pdf}</div>
                            )}
                        </>
                      )}
                      {selectedType === 'icon' && (
                        <>
                          <UploadFile
                            title=""
                            name="icon"
                            onChange={(file) => setFieldValue('icon', file)}
                            variant="previewImage"
                            apiUrls={
                              documentId && typeof values.icon === 'string'
                                ? [values.icon]
                                : []
                            }
                            onRemove={() => {
                              setFieldValue('icon', null);
                            }}
                          />
                          {typeof errors.icon === 'string' &&
                            errors.icon &&
                            touched.icon && (
                              <div style={{ color: 'red' }}>{errors.icon}</div>
                            )}
                        </>
                      )}
                    </InfoRowStyled>
                  </NewDocumentWrapper>
                  {apiError && (
                    <div style={{ color: 'red', marginTop: '10px' }}>
                      {apiError}
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </ModalContentWrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '20px',
            padding: '0 20px 20px',
          }}
        >
          {isOpenModal && onClose ? (
            <Button
              radius="md"
              variant="ghost"
              style={{
                border: `1px solid ${theme.colors.dark.neutral_250}`,
                borderRadius: '10px',
                color: theme.colors.text,
                fontFamily: theme.fontFamily.roboto,
                fontWeight: theme.fontWeights.medium,
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
          ) : (
            <Button
              radius="md"
              variant="ghost"
              style={{
                border: `1px solid ${theme.colors.dark.neutral_10}`,
                borderRadius: '10px',
              }}
            >
              Save as drafts
            </Button>
          )}

          <Button
            type="submit"
            radius="normal"
            isLoading={loading}
            onClick={() => formikRef.current?.handleSubmit()}
            style={{ backgroundColor: theme.colors.brandColorBlue }}
          >
            {documentId ? 'Update Document' : 'Save'}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CreateDocuments;
