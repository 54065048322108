import { FormikProps } from 'formik';
import { CSSProperties, useRef, useState } from 'react';

import { ReactComponent as ChevronDownSVG } from '@/assets/chevron-down.svg';
import useDetectOutsideClick from '@/hooks/useDetectOutsideClick';

import Input from '../Input';
import {
  IndividualOption,
  LabelandOptionWrapper,
  OptionWrapper,
  SelectBoxStyled,
  SelectInput,
} from './style';

type Option = {
  value: string | number;
  label: string;
};

type Props = {
  formikProps?: FormikProps<any>;
  label?: string;
  options: Option[];
  name?: string;
  placeholder: string;
  onSelect?: (value: string | number) => void;
  isHideBorder?: boolean;
  isReduceIconGap?: boolean;
  selected?: string | number;
  style?: CSSProperties;
  onChange?: boolean;
};

const Select = ({
  label,
  options,
  name,
  placeholder,
  formikProps,
  isHideBorder = false,
  isReduceIconGap = false,
  onSelect,
  style,
  selected,
  onChange,
}: Props) => {
  const containerRef = useRef(null);
  const [search, setSearch] = useState('');

  const [isOpen, setIsOpen] = useDetectOutsideClick(containerRef, false) as [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ];

  const handleSelect = (value: string | number, option) => {
    formikProps?.setFieldValue(name || '', value);
    if (onSelect) {
      onSelect(value, option);
    }
    setIsOpen((prev) => {
      return !prev;
    });
  };

  const filteredOptions = search
    ? options.filter((opt) =>
        opt.label.toLowerCase().includes(search.toLowerCase())
      )
    : options;

  const handleSearch = (e: any) => {
    e.preventDefault();
    const searchTerm = e.target.value;
    setSearch(searchTerm);
  };

  const selectedOptionLabel =
    options.find((option) => {
      return selected
        ? option.value === selected
        : option.value === formikProps?.values[name || ''];
    })?.label || '';

  return (
    <LabelandOptionWrapper>
      {label && <label>{label}</label>}
      <SelectBoxStyled
        onClick={() => setIsOpen(!isOpen)}
        $isHideBorder={isHideBorder}
        ref={containerRef}
        style={{ ...style }}
      >
        <SelectInput
          value={selectedOptionLabel}
          placeholder={placeholder}
          readOnly
          className="input"
        />
        <ChevronDownSVG
          style={{
            marginLeft: isReduceIconGap ? '-20px' : '',

            transform: isOpen ? 'rotate(180deg)' : '',
            transition: 'all 0.3s ease',
          }}
        />
      </SelectBoxStyled>
      {isOpen && (
        <OptionWrapper onClick={(e) => e.stopPropagation()}>
          {onChange && (
            <Input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={handleSearch}
              // iconLeft={
              //   <img src="/assets/icons/Search.svg" alt="serach icon" />
              // }
              containerStyle={{
                borderRadius: '27px',
                position: 'sticky',
                top: '0',
                backgroundColor: 'white',
              }}
            />
          )}
          {filteredOptions.map((option, idx) => {
            const selectedOption = !selected
              ? formikProps?.values[name || ''] === option.value
              : selected === option.value;
            return (
              <IndividualOption
                key={idx}
                onClick={() => handleSelect(option.value, option)}
                $selectedOption={selectedOption}
              >
                {option.label}
              </IndividualOption>
            );
          })}
        </OptionWrapper>
      )}
    </LabelandOptionWrapper>
  );
};

export default Select;
