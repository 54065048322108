import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

import downloadCenter from '@/apiConfigs/downloadCenter';
import CountrySelection from '@/components/CountrySelection';
import TextEditor from '@/components/TextEditor';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Modal from '@/designComponents/Modal';
import { ModalContentWrapper } from '@/designComponents/Modal/style';
import Select from '@/designComponents/Select';
import Typography from '@/designComponents/Typography';
import UploadFile from '@/designComponents/UploadFile';
import { switchBodyContent } from '@/pages/Documents/function';
import { InfoRowStyled, NewDocumentWrapper } from '@/pages/Documents/style';
import { RootState, useAppDispatch } from '@/store';
import {
  addDownloadCenterResource,
  getAllDownloadGroup,
  getAllDownloadResources,
} from '@/store/downloadCenter/functions';
import requestAPI from '@/utils/requestAPI';
import { uploadFilesToS3OnlyKeys } from '@/utils/s3Upload';

import { ValidationSchema } from './validation';

export type InitialValuesType = {
  title: string;
  type?: string;
  downloadCenterCategoryId?: string;
  downloadCenterGroupId?: string;
  category?: string;
  region?: string;
  editorHtml: string;
  pdf?: File | null | string;
  icon?: File | null | string;
  position?: number;
  // body?: {
  //   content: string;
  //   contentType: string;
  // };
};

type Props = {
  isOpenModal: boolean;
  onClose: () => void;
  resourcesId?: string;
};
const typeoptions = [
  { value: 'Document', label: 'Document' },
  { value: 'Diagram', label: 'Diagram' },
  { value: 'Table', label: 'Table' },
];

const CreateResources = ({ isOpenModal, onClose, resourcesId }: Props) => {
  const { resourceId } = useParams();

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [resource, setResource] = useState();
  const [selectedType, setSelectedType] = useState('text');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('ie');
  const [initialValues, setInitialValues] = useState<InitialValuesType>({
    title: '',
    type: '',
    downloadCenterCategoryId: '',
    downloadCenterGroupId: '',
    category: '',
    editorHtml: '',
    pdf: null,
    icon: null,
    region: selectedCountry,
    position: 0,
    // body: {
    //   content: '',
    //   contentType: '',
    // },
  });

  useEffect(() => {
    if ((resourceId && resource) || (resourcesId && resource)) {
      const contentType = resource.body?.contentType;
      switch (contentType) {
        case 'html':
          setSelectedType('text');
          break;
        case 'pdf':
          setSelectedType('pdf');
          break;
        case 'image':
          setSelectedType('icon');
          break;
        default:
          setSelectedType('text');
      }
    } else {
      setSelectedType('text');
    }
  }, [resourceId, resourcesId, resource]);
  const getSingleResource = async (id: string) => {
    const data = await requestAPI(
      downloadCenter.getSingleDownloadCenterResource(id)
    );
    setResource(data);
  };
  useEffect(() => {
    if (resourcesId) {
      getSingleResource(resourcesId);
    }
  }, [isOpenModal]);
  useEffect(() => {
    if (resourceId) {
      getSingleResource(resourceId);
    }
  }, []);

  const categoryState = useSelector(
    (state: RootState) => state.downloadCenterCategory
  );

  const { downloadCenterCategory } = categoryState;

  const downloadGroups = useSelector(
    (state: RootState) => state.downloadCenterCategory.downloadCenterGroup
  );

  // useEffect(() => {
  //   if (resourcesId && resource) {
  //     const currentType = resource?.body?.contentType || 'text';
  //     setSelectedType(
  //       currentType === 'pdf'
  //         ? 'pdf'
  //         : currentType === 'image'
  //           ? 'icon'
  //           : 'text'
  //     );

  //     setInitialValues({
  //       title: resource?.title || '',
  //       type: resource?.type,
  //       downloadCenterCategoryId: resource?.downloadCenterCategoryId?._id,

  //       downloadCenterGroupId: resource?.downloadCenterGroupId?._id || '',
  //       category: resource?.category || '',
  //       editorHtml: resource?.body && switchBodyContent('html', resource?.body),
  //       pdf: resource?.body && switchBodyContent('pdf', resource.body),
  //       icon: resource?.body && switchBodyContent('image', resource.body),
  //       region: resource?.region || selectedCountry,
  //       position: resource?.position || 0,
  //     });
  //   }
  // }, [resourcesId, resource]);

  useEffect(() => {
    if ((resourcesId && resource) || (resourceId && resource)) {
      const currentType = resource?.body?.contentType || 'text';
      setSelectedType(
        currentType === 'pdf'
          ? 'pdf'
          : currentType === 'image'
            ? 'icon'
            : 'text'
      );

      setInitialValues({
        title: resource?.title || '',
        type: resource?.type,
        downloadCenterCategoryId: resource?.downloadCenterCategoryId?._id,

        downloadCenterGroupId: resource?.downloadCenterGroupId?._id || '',
        category: resource?.category || '',
        editorHtml: resource?.body && switchBodyContent('html', resource?.body),
        pdf: resource?.body && switchBodyContent('pdf', resource.body),
        icon: resource?.body && switchBodyContent('image', resource.body),
        region: resource?.region || selectedCountry,
        position: resource?.position || 0,
      });
    }
  }, [resourceId, resource]);
  console.log(
    initialValues,
    'initial values',
    switchBodyContent('image', resource?.body)
  );

  const handleFormSubmit = async (val: InitialValuesType) => {
    let content: string = '';
    let contentType: string = '';
    if (selectedType === 'text') {
      content = val.editorHtml;
      contentType = 'html';
    } else if (selectedType === 'pdf' && val.pdf) {
      if (typeof val.pdf !== 'string') {
        const pdfKeys = await uploadFilesToS3OnlyKeys([val.pdf]);
        if (pdfKeys && pdfKeys.length > 0) {
          content = pdfKeys[0];
          contentType = 'pdf';
        } else {
          toast.error('Failed to upload PDF');
          return;
        }
      } else {
        content = val.pdf;
        contentType = 'pdf';
      }
    } else if (selectedType === 'icon' && val.icon) {
      if (typeof val.icon !== 'string') {
        const imageKeys = await uploadFilesToS3OnlyKeys([val.icon]);
        if (imageKeys && imageKeys.length > 0) {
          content = imageKeys[0];
          contentType = 'image';
        } else {
          toast.error('Failed to upload image');
          return;
        }
      } else {
        content = val.icon;
        contentType = 'image';
      }
    }
    try {
      setLoading(true);
      await dispatch(
        addDownloadCenterResource({
          title: val.title,
          type: val.type,
          downloadCenterCategoryId: val.downloadCenterCategoryId,
          downloadCenterGroupId: val.downloadCenterGroupId,
          category: val.category,
          position: val.position,
          region: selectedCountry,
          body: {
            content,
            contentType,
          },
        })
      );
      setLoading(false);
      toast.success('Download Resources created');
      dispatch(getAllDownloadResources({ limit: 10, page: 1 }));
      onClose();
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      setErrorMessage(error.message);
    }
  };

  const handleUpdateResources = async (val: InitialValuesType) => {
    let content: string = '';
    let contentType: string = '';

    if (selectedType === 'text') {
      content = val.editorHtml;
      contentType = 'html';
    } else if (selectedType === 'pdf' && val.pdf) {
      if (typeof val.pdf !== 'string') {
        const pdfKeys = await uploadFilesToS3OnlyKeys([val.pdf]);
        if (pdfKeys && pdfKeys.length > 0) {
          content = pdfKeys[0];
          contentType = 'pdf';
        } else {
          toast.error('Failed to upload PDF');
          return;
        }
      } else {
        content = val.pdf;
        contentType = 'pdf';
      }
    } else if (selectedType === 'icon' && val.icon) {
      if (typeof val.icon !== 'string') {
        const imageKeys = await uploadFilesToS3OnlyKeys([val.icon]);
        if (imageKeys && imageKeys.length > 0) {
          content = imageKeys[0];
          contentType = 'image';
        } else {
          toast.error('Failed to upload image');
          return;
        }
      } else {
        content = val.icon;
        contentType = 'image';
      }
    }

    try {
      setLoading(true);
      await requestAPI(
        downloadCenter.updateDownloadCenterResource(resourceId || resourcesId, {
          title: val.title,
          type: val.type,
          downloadCenterCategoryId: val.downloadCenterCategoryId,
          downloadCenterGroupId: val.downloadCenterGroupId,
          category: val.category,
          position: val.position,
          region: selectedCountry,
          body: {
            content: content,
            contentType: contentType,
          },
        })
      );

      setLoading(false);
      toast.success('Download Resources updated successfully');
      dispatch(getAllDownloadResources({ limit: 10, page: 1 }));
      onClose();
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    dispatch(getAllDownloadGroup({}));
  }, []);
  return (
    <>
      <Modal
        isOpen={isOpenModal}
        onClose={onClose}
        title={
          resourceId || resourcesId ? 'Update Resource' : 'Create New Resource'
        }
      >
        <ModalContentWrapper>
          <Formik
            initialValues={initialValues}
            onSubmit={
              resourceId || resourcesId
                ? handleUpdateResources
                : handleFormSubmit
            }
            validationSchema={() => ValidationSchema(selectedType)}
            enableReinitialize
          >
            {({ values, setFieldValue, handleSubmit, errors, touched }) => {
              console.log(values, 'values');

              return (
                <Form onSubmit={handleSubmit}>
                  <NewDocumentWrapper>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        maxWidth: '200px',
                      }}
                    >
                      <CountrySelection
                        selectedCountry={selectedCountry}
                        setSelectedCountry={setSelectedCountry}
                      />
                    </div>
                    <InfoRowStyled>
                      <Typography
                        fontFamily="roboto"
                        size="normal"
                        fontWeight="medium"
                        color="dark.neutral_45"
                      >
                        Name <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Input
                        name="title"
                        value={values.title}
                        onChange={(e) => setFieldValue('title', e.target.value)}
                      />
                      {errors.title && touched.title && (
                        <div style={{ color: 'red' }}>{errors.title}</div>
                      )}
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography
                        fontFamily="roboto"
                        size="normal"
                        fontWeight="medium"
                        color="dark.neutral_45"
                      >
                        Type <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Select
                        name="type"
                        placeholder="Select Type"
                        options={typeoptions}
                        selected={values.type}
                        onSelect={(value) => setFieldValue('type', value)}
                      />
                      {errors.type && touched.type && (
                        <div style={{ color: 'red' }}>{errors.type}</div>
                      )}
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography
                        fontFamily="roboto"
                        size="normal"
                        fontWeight="medium"
                        color="dark.neutral_45"
                      >
                        Category <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Select
                        name="downloadCenterCategoryId"
                        placeholder="Select Category"
                        options={[
                          ...(downloadCenterCategory?.map((cat: any) => ({
                            label: cat.title,
                            value: cat._id,
                          })) || []),
                        ]}
                        selected={values.downloadCenterCategoryId}
                        onSelect={(value) =>
                          setFieldValue('downloadCenterCategoryId', value)
                        }
                      />
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography
                        fontFamily="roboto"
                        size="normal"
                        fontWeight="medium"
                        color="dark.neutral_45"
                      >
                        Group <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Select
                        name="downloadCenterGroupId"
                        placeholder="Select Group"
                        options={[
                          ...(downloadGroups?.map((group: any) => ({
                            label: group?.title,
                            value: group._id,
                          })) || []),
                        ]}
                        selected={values.downloadCenterGroupId}
                        onSelect={(value) =>
                          setFieldValue('downloadCenterGroupId', value)
                        }
                      />
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography
                        fontFamily="roboto"
                        size="normal"
                        fontWeight="medium"
                        color="dark.neutral_45"
                      >
                        Position <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Input
                        name="position"
                        value={values.position}
                        onChange={(e) =>
                          setFieldValue('position', e.target.value)
                        }
                      />
                      {errors.position && touched.position && (
                        <div style={{ color: 'red' }}>{errors.position}</div>
                      )}
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography
                        fontFamily="roboto"
                        size="normal"
                        fontWeight="medium"
                        color="dark.neutral_45"
                      >
                        Category <span style={{ color: 'red' }}>*</span>
                      </Typography>
                      <Input
                        name="category"
                        value={values.category}
                        onChange={(e) =>
                          setFieldValue('category', e.target.value)
                        }
                      />
                    </InfoRowStyled>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Button
                        variant={selectedType === 'text' ? 'primary' : 'ghost'}
                        onClick={() => setSelectedType('text')}
                        type="button"
                      >
                        Text
                      </Button>

                      <Button
                        variant={selectedType === 'icon' ? 'primary' : 'ghost'}
                        onClick={() => setSelectedType('icon')}
                        type="button"
                      >
                        Image
                      </Button>
                      <Button
                        variant={selectedType === 'pdf' ? 'primary' : 'ghost'}
                        onClick={() => setSelectedType('pdf')}
                        type="button"
                      >
                        PDF
                      </Button>
                    </div>
                    <InfoRowStyled>
                      <Typography className="text">
                        {selectedType === 'text' && (
                          <>
                            Text <span style={{ color: 'red' }}>*</span>
                          </>
                        )}
                        {selectedType === 'pdf' && (
                          <>
                            Upload PDF <span style={{ color: 'red' }}>*</span>
                          </>
                        )}
                        {selectedType === 'icon' && (
                          <>
                            Upload Image <span style={{ color: 'red' }}>*</span>
                          </>
                        )}
                      </Typography>
                      {selectedType === 'text' && (
                        <>
                          <TextEditor
                            initialHtml={values.editorHtml}
                            setEditorState={({ html }) =>
                              setFieldValue('editorHtml', html)
                            }
                          />
                          {errors.editorHtml && touched.editorHtml && (
                            <div style={{ color: 'red' }}>
                              {errors.editorHtml}
                            </div>
                          )}
                        </>
                      )}
                      {selectedType === 'pdf' && (
                        <>
                          <UploadFile
                            title=""
                            acceptedFiles=".pdf"
                            name="pdf"
                            onChange={(file) => setFieldValue('pdf', file)}
                            variant="previewImage"
                            apiUrls={
                              (resourceId || resourcesId) &&
                              typeof values.pdf === 'string'
                                ? [values.pdf]
                                : []
                            }
                            onRemove={() => {
                              setFieldValue('pdf', null);
                            }}
                          />
                        </>
                      )}
                      {selectedType === 'icon' && (
                        <>
                          <UploadFile
                            title=""
                            name="icon"
                            onChange={(file) => setFieldValue('icon', file)}
                            variant="previewImage"
                            apiUrls={
                              (resourceId || resourcesId) &&
                              typeof values.icon === 'string'
                                ? [values.icon]
                                : []
                            }
                            onRemove={() => {
                              setFieldValue('icon', null);
                            }}
                          />
                        </>
                      )}
                    </InfoRowStyled>
                  </NewDocumentWrapper>
                  {errorMessage && (
                    <div style={{ color: 'red', marginTop: '10px' }}>
                      {errorMessage}
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '50px',
                    }}
                  >
                    <Button
                      radius="md"
                      variant="ghost"
                      style={{
                        border: `1px solid ${theme.colors.dark.neutral_250}`,
                        borderRadius: '10px',
                        color: theme.colors.text,
                        fontFamily: theme.fontFamily.roboto,
                        fontWeight: theme.fontWeights.medium,
                      }}
                      onClick={() => onClose()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      radius="normal"
                      isLoading={loading}
                      style={{ backgroundColor: theme.colors.brandColorBlue }}
                    >
                      {resourceId || resourcesId ? 'Update' : 'Save'}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </ModalContentWrapper>
        {/* </div> */}
      </Modal>
    </>
  );
};

export default CreateResources;
