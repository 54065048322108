import Typography from '@/designComponents/Typography';
import { toCapitalCase } from '@/utils/conversion';
import { humanReadableDateWithTime } from '@/utils/date';

import { InformationRowWrapper } from '../style';

type Props = {
  id: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  userName?: string;
  userStauts?: 'danger';
  userStautsTitle?: string;
  city?: string;
  email?: string;
  region?: string;
  comments?: string;
  tradingName?: string;
  yearsOfTrading?: string;
  businessAddress?: string;
  paymentStatus?: 'completed' | 'inComplete';
  paymentTitle?: string;
  createdAt?: string;
  updateAt: string;
  subsPlan?: string;
  accountType?: string;
};
const ProfessionalUserInformation = ({
  createdAt,
  email,
  fullName,
  id,
  updateAt,
  firstName,
  lastName,
  city,
  subsPlan,
  accountType,
}: Props) => {
  return (
    <InformationRowWrapper>
      <>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            ID
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {id}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Full Name
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {fullName}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            First Name
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {firstName}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Last Name
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {lastName}
          </Typography>
        </div>
        {/* <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            User
          </Typography>
          <div style={{ display: 'flex', gap: '13px', alignItems: 'center' }}>
            <Typography
              fontFamily="roboto"
              fontWeight="normal"
              color="dark.neutral_450"
              size="normal"
            >
              {userName}
            </Typography>

            <Typography
              fontFamily="roboto"
              fontWeight="normal"
              color="dark.neutral_450"
              size="normal"
            >
              Empty
            </Typography>
          </div>
        </div> */}
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Email
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {email}
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Trade
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Architect
          </Typography>
        </div>
        {/* <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Regions
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Ireland
          </Typography>
        </div> */}

        {city && (
          <div className="text">
            <Typography
              fontFamily="roboto"
              fontWeight="normal"
              color="dark.neutral_450"
              size="normal"
            >
              City
            </Typography>
            <Typography
              fontFamily="roboto"
              fontWeight="normal"
              color="dark.neutral_450"
              size="normal"
            >
              {city}
            </Typography>
          </div>
        )}

        {/* <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            User Profile Photo Review
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Empty
          </Typography>
        </div> */}
        {/* <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Onboarded
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Empty
          </Typography>
        </div> */}
        {/* <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Is Onboarded
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Empty
          </Typography>
        </div> */}
        {/* <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Stripe Customer ID
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Empty
          </Typography>
        </div> */}
        {/* <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Claim Account Link
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="primary"
            size="normal"
            style={{ textDecoration: 'underline' }}
          >
            https://housebuilduk.com/professionals/claim/b117a875e67d0842dcce
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Facebook Link
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="primary"
            size="normal"
            style={{ textDecoration: 'underline' }}
          >
            http://192.168.20.126:5000/
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Instagram Link
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="primary"
            size="normal"
            style={{ textDecoration: 'underline' }}
          >
            http://192.168.20.126:5000/
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            LinkedIn Link
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="primary"
            size="normal"
            style={{ textDecoration: 'underline' }}
          >
            http://192.168.20.126:5000/
          </Typography>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Twitter Link
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="primary"
            size="normal"
            style={{ textDecoration: 'underline' }}
          >
            http://192.168.20.126:5000/
          </Typography>
        </div> */}
        {createdAt && (
          <div className="text">
            <Typography
              fontFamily="roboto"
              fontWeight="normal"
              color="dark.neutral_450"
              size="normal"
            >
              Created At
            </Typography>
            <Typography
              fontFamily="roboto"
              fontWeight="normal"
              color="dark.neutral_450"
              size="normal"
              style={{ textDecoration: 'underline' }}
            >
              {humanReadableDateWithTime(createdAt)}
            </Typography>
          </div>
        )}
        {updateAt && (
          <div className="text">
            <Typography
              fontFamily="roboto"
              fontWeight="normal"
              color="dark.neutral_450"
              size="normal"
            >
              Update At
            </Typography>
            <Typography
              fontFamily="roboto"
              fontWeight="normal"
              color="dark.neutral_450"
              size="normal"
              style={{ textDecoration: 'underline' }}
            >
              {humanReadableDateWithTime(updateAt)}
            </Typography>
          </div>
        )}
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Account Type
          </Typography>
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            {toCapitalCase(accountType)}
          </Typography>
        </div>
        {subsPlan && (
          <div className="text">
            <Typography
              fontFamily="roboto"
              fontWeight="normal"
              color="dark.neutral_450"
              size="normal"
            >
              Subscription Plan
            </Typography>
            <Typography
              fontFamily="roboto"
              fontWeight="normal"
              color="dark.neutral_450"
              size="normal"
            >
              {toCapitalCase(subsPlan)}
            </Typography>
          </div>
        )}
        {/* <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Go Green
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tag
              title="No X"
              hidePoint
              variant="danger"
              style={{ borderRadius: '4px' }}
            />
          </div>
        </div> */}
        {/* <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Featured
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tag
              title="Yes"
              hidePoint
              variant="active"
              style={{ borderRadius: '4px' }}
            />
          </div>
        </div>
        <div className="text">
          <Typography
            fontFamily="roboto"
            fontWeight="normal"
            color="dark.neutral_450"
            size="normal"
          >
            Is Verified
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tag
              title="Yes"
              hidePoint
              variant="active"
              style={{ borderRadius: '4px' }}
            />
          </div>
        </div> */}
      </>
    </InformationRowWrapper>
  );
};

export default ProfessionalUserInformation;
