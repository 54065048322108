import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { paginationType } from '@/apiConfigs/cities';
import { PaginationType } from '@/apiConfigs/documents';
import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import { Table } from '@/components';
import Pagination from '@/components/Pagination';
import { citiesAnalyticsKeys } from '@/constants';
import Button from '@/designComponents/Button';
import { ButtonTab } from '@/designComponents/ButtonTab';
import Input from '@/designComponents/Input';
import { TableDataType } from '@/designComponents/Table';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import { RootState, useAppDispatch } from '@/store';
import { getAllCities } from '@/store/cities/functions';
import { toCapitalCase } from '@/utils/conversion';
import { debounce } from '@/utils/debounce';

import AddCity from './AddCity';

type Props = {};

const Cities = (_props: Props) => {
  const dispatch = useAppDispatch();

  const [paginationParams, setPaginationParams] = useState<paginationType>({
    page: 1,
    limit: 10,
    search: '',
  });
  const citiesState = useSelector((state: RootState) => state.cities);
  const { cities, pagination, analytics } = citiesState;
  const [cardKey, setCardKey] = useState<string | null>(null);

  const [selectedCity, setSelectedCity] = useState<CityValues | null>(null);

  const fetchCities = (myParams: paginationType) => {
    const noEmptyParams = Object.entries(myParams).reduce((acc, val) => {
      if (!val[1]) {
        return acc;
      }
      acc = { ...acc, [val[0]]: val[1] };
      return acc;
    }, {});
    dispatch(getAllCities(noEmptyParams));
  };
  const delayedFetch = useCallback(debounce(fetchCities, 300), []);

  useEffect(() => {
    fetchCities(paginationParams);
  }, [dispatch]);

  const handleEditCity = (city: string) => {
    setSelectedCity(city);
  };

  useHeaderTitle('Cities');

  const tableData: TableDataType = {
    headings: [
      { key: 'name', label: 'Name' },
      { key: 'region', label: 'Region' },
      { key: 'professionalCount', label: 'Professionals' },
      { key: 'jobPostsCount', label: 'Job Posts' },
      {
        key: 'actions',
        label: 'Action',
      },
    ],
    body: Array.isArray(cities)
      ? cities?.map((city) => ({
          id: city._id,
          name: city.name,
          region: city.region === 'ie' ? 'Ireland' : 'UK',
          professionalCount: 0,
          jobPostsCount: 0,
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="gray"
                radius="normal"
                onClick={() => handleEditCity(city)}
              >
                Edit
              </Button>
            </div>
          ),
        }))
      : [],
  };

  const updateParamsAndFetch = (updatedPagination: PaginationType) => {
    setPaginationParams(updatedPagination);
    delayedFetch(updatedPagination);
  };

  const handleCurrentPage = (page: number) => {
    const updatedPagination = { ...paginationParams, page: page };
    updateParamsAndFetch(updatedPagination);
  };

  const handleLimitChange = (limit: number) => {
    const updatedPagination = { ...paginationParams, page: 1, limit };
    updateParamsAndFetch(updatedPagination);
  };

  // const handleLimitChange = (limit: number) => {
  //   setPaginationParams((prev) => ({ ...prev, page: 1, limit }));
  // };

  const onCityTypeChange = (key: string) => {
    setCardKey(key);
    const updatedPagination = {
      ...paginationParams,
      page: 1,
      filter: { region: key },
    };
    updateParamsAndFetch(updatedPagination);
  };
  const onSearchValueChange = (value: string) => {
    const updatedPagination = {
      ...paginationParams,
      page: 1,
      search: value,
    };
    updateParamsAndFetch(updatedPagination);
  };
  const handleNextPage = () => {
    if (
      paginationParams.page &&
      pagination?.totalPages &&
      paginationParams?.page < pagination.totalPages
    ) {
      handleCurrentPage(paginationParams.page + 1);
    }
  };
  const handleLastPage = () => {
    if (
      pagination?.totalPages &&
      paginationParams.page !== pagination.totalPages
    ) {
      handleCurrentPage(pagination.totalPages);
    }
  };

  const reducedAllCount = analytics
    ? Object.values(analytics).reduce(
        (prev, curr: { key: string; value: string }) => {
          return (prev += curr.value);
        },
        0
      )
    : 0;

  const cardData =
    analytics && Object.keys(analytics).length
      ? citiesAnalyticsKeys.map((ak) => ({
          title: toCapitalCase(ak),
          count:
            ak === 'all' ? reducedAllCount : analytics[ak]?.value || `${0}`,
          key:
            ak === 'uk' ? 'uk' : ak === 'ie' ? 'ie' : ak === 'all' ? null : ak,
        }))
      : [];

  const tabData = (
    <ButtonTab
      items={cardData}
      activeIndex={cardData.findIndex((x) => x.key === cardKey)}
      setActiveIndex={(index) => onCityTypeChange(cardData[index].key)}
    />
  );

  return (
    <div>
      <div style={{ marginBottom: '16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Input
            value={paginationParams.search}
            onChange={(e) => {
              const { value } = e.target;
              onSearchValueChange(value);
            }}
            placeholder="Search City"
            iconLeft={<SearchSVG />}
          />

          <AddCity selectedCity={selectedCity} />
        </div>
      </div>
      <Table
        tableData={tableData}
        customRow={tabData}
        noData="No data to display."
        showCheckBox={false}
        maxWidthForCardView="768px"
        headingBgColor="#f0f0f0"
        showMenuIcon={false}
        evenRowBgColor="#fafafa"
      />

      <Pagination
        activePage={paginationParams.page || 1}
        onCurrentPageChange={handleCurrentPage}
        totalPages={pagination?.totalPages || 1}
        limit={paginationParams.limit || 10}
        onLimitChange={handleLimitChange}
        onNext={handleNextPage}
        onLast={handleLastPage}
      />
    </div>
  );
};

export default Cities;
