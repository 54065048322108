import { createSlice } from '@reduxjs/toolkit';

import { toKebabCase } from '@/utils/conversion';

import {
  deleteUser,
  getAllUsers,
  getAllUsersWithAnalytics,
  getSingleUsers,
} from './functions';

const initialState: any = {
  users: [],
  singleUser: {},
  analytics: {},
  pagination: {
    total: 0,
    limit: 10,
    currentPage: 0,
    totalPages: 1,
  },
  error: null,
  loading: false,
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch users. Try again later.';
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        console.log({ action }, ':: ACTION ');

        state.loading = false;
        state.error = null;
        state.users = action.payload.data;
        state.pagination = action.payload.pagination;
      });
    builder
      .addCase(getSingleUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSingleUsers.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch user. Try again later.';
      })
      .addCase(getSingleUsers.fulfilled, (state, action) => {
        console.log({ action }, ':: ACTION ');

        state.loading = false;
        state.error = null;
        state.singleUser = action.payload;
      });
    builder
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to delete user. Try again later.';
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.users = state.users.filter(
          (user: any) => user.id !== action.meta.arg
        );
      });
    builder
      .addCase(getAllUsersWithAnalytics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllUsersWithAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch users. Try again later.';
      })
      .addCase(getAllUsersWithAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.users = action.payload.users.data;
        state.pagination = action.payload.users.pagination;
        state.analytics = action.payload.analytics
          ? Object.entries(action.payload.analytics).reduce(
              (acc, [key, value]) => {
                acc = { ...acc, [toKebabCase(key)]: { key, value } };
                return acc;
              },
              {}
            )
          : {};
      });
  },
});

export const userReducers = userSlice.reducer;
