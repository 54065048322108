import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// import UserPNG from '@/assets/user-img.png';
import Avatar from '@/designComponents/Avatar';
import Typography from '@/designComponents/Typography';
// import CardTab from '@/designComponents/CardTab';
// import CardTab from '@/designComponents/CardTab';
import { RootState, useAppDispatch } from '@/store';
import { getSingleUsers } from '@/store/users/functions';

import BusinessDetails from '../../../../components/UserDetails/BusinessDetails';
import CrumbsWithButton from '../../../../components/UserDetails/CrumbsWithButton';
import HeadingTitle from '../../../../components/UserDetails/HeadingTitle';
import Subscription from '../../../../components/UserDetails/Subscription';
import { professionalBreadCrumbData } from '../../data';
import { AccountDetailsWrapper, InfoRowStyled } from '../style';
import ProfessionalUserInformation from './ProfessionalUserInformation';

const ProfessionalUsers = () => {
  const { userId } = useParams();
  const dispatch = useAppDispatch();

  const singleUser = useSelector((state: RootState) => state.users.singleUser);

  useEffect(() => {
    if (userId) {
      dispatch(getSingleUsers(userId));
    }
  }, [dispatch, userId]);
  return (
    <AccountDetailsWrapper>
      <CrumbsWithButton
        breadCrumbData={professionalBreadCrumbData(singleUser?.accountType)}
        deleteButtonText="Delete Professional"
        // editButtonText="Edit Professional"
      />
      <div className="main-content">
        {/* <InfoRowStyled>
          <HeadingTitle title="Professional Users Analytics" />
          <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
            {' '}
            {detailsCardData.map((card, idx) => (
              <CardTab
                title={card.title}
                count={card.count}
                key={idx}
                enableBorder
                mainContentstyle={{ padding: '13px 12px' }}
                cardStyle={{
                  maxWidth: '190px',
                  width: '100%',
                  minHeight: '112px',
                }}
              />
            ))}
          </div>
        </InfoRowStyled> */}

        <InfoRowStyled>
          <HeadingTitle title="Active Subscription" />
          <Subscription />
        </InfoRowStyled>
        <InfoRowStyled>
          <HeadingTitle title="User Profile" />
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <Avatar
              avatarUrl={singleUser?.avatarUrl}
              firstName={singleUser?.firstName}
              lastName={singleUser?.lastName}
            />
            <Typography>
              {singleUser?.firstName + ' ' + singleUser?.lastName}
            </Typography>
          </div>
          {/* <UserProfileWIthImage
            email={singleUser?.email}
            // imgUrl={UserPNG}
            name={singleUser?.firstName + ' ' + singleUser.lastName}
          /> */}
        </InfoRowStyled>
        <InfoRowStyled>
          <HeadingTitle title="User Details" />
          <ProfessionalUserInformation
            createdAt={singleUser?.createdAt}
            email={singleUser?.email}
            fullName={singleUser?.firstName + ' ' + singleUser.lastName}
            id={singleUser?._id}
            paymentStatus="completed"
            paymentTitle="Completed"
            region={singleUser?.region}
            updateAt={singleUser?.updatedAt}
            userName="Darrell"
            userStauts="danger"
            userStautsTitle="Destroy User"
            firstName={singleUser?.firstName}
            lastName={singleUser?.lastName}
            city={singleUser?.city}
            subsPlan={singleUser?.subscription?.name}
            accountType={singleUser?.accountType}
          />
        </InfoRowStyled>
        {singleUser?.business?.name && (
          <InfoRowStyled>
            <HeadingTitle title="Business Details" />
            <BusinessDetails
              businessAddress={singleUser?.business?.address?.city}
              businessName={singleUser?.business?.name}
              businessType={singleUser?.business?.tradeType}
              businessLogo={singleUser?.business?.banner?.url}
            />
          </InfoRowStyled>
        )}
        {/* <InfoRowStyled>
          <HeadingTitle title="Events" />
          <Events events={eventsData} />
        </InfoRowStyled> */}
        {/* <InfoRowStyled>
          <HeadingTitle title="Sent Emails" />
          <Events events={sentEmailData} isEmail />
        </InfoRowStyled>
        <InfoRowStyled>
          <HeadingTitle title="Payments" />
          <Payments payments={paymentsData} />
        </InfoRowStyled> */}
      </div>
    </AccountDetailsWrapper>
  );
};

export default ProfessionalUsers;
