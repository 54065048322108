import { createAsyncThunk } from '@reduxjs/toolkit';

import { PaginationType } from '@/apiConfigs/documents';
import users from '@/apiConfigs/users';
import requestAPI from '@/utils/requestAPI';

export const getAllUsers = createAsyncThunk(
  'users/getAllUsers',
  async (params: PaginationType) => {
    const response = await requestAPI(users.getUsers(params));

    return response;
  }
);

export const getSingleUsers = createAsyncThunk(
  'users/getSingleUsers',
  async (userId: string) => {
    const response = await requestAPI(users.getUserDetails(userId));

    return response;
  }
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (userId: string) => {
    const response = await requestAPI(users.deleteUser(userId));
    return response;
  }
);

export const getAllUsersWithAnalytics = createAsyncThunk(
  'users/getAllUsersWithAnalytics',
  async (params: PaginationType) => {
    const response = await requestAPI(users.getUsersWithAnalytics(params));
    return response;
  }
);
