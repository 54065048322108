import { createSlice } from '@reduxjs/toolkit';

import {
  addDownloadCenterCategory,
  addDownloadCenterGroup,
  addDownloadCenterResource,
  getAllDownloadCategory,
  getAllDownloadGroup,
  getAllDownloadResources,
} from './functions';

const initialState: any = {
  downloadCenterCategory: [],
  downloadCenterGroup: [],
  downloadCenterResource: [],
  pagination: {
    total: 0,
    limit: 10,
    currentPage: 1,
    totalPages: 1,
  },
  error: null,
  loading: false,
};

const DownloadcenterSlice = createSlice({
  name: 'downloadCenterCategory',
  initialState,
  reducers: {
    clearDownloadCenterError: (state) => {
      return {
        ...state,
        error: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addDownloadCenterCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addDownloadCenterCategory.fulfilled, (state, action) => {
        state.downloadCenterCategory = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(addDownloadCenterCategory.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action?.payload || 'Failed to add category. Try again later.';
      });
    builder
      .addCase(getAllDownloadCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(getAllDownloadCategory.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch documents. Try again later.';
      })
      .addCase(getAllDownloadCategory.fulfilled, (state, action) => {
        state.loading = false;

        state.downloadCenterCategory = action.payload.data.data;
        state.pagination = action.payload.data.pagination;
      });
    builder
      .addCase(addDownloadCenterGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addDownloadCenterGroup.fulfilled, (state, action) => {
        state.downloadCenterGroup = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(addDownloadCenterGroup.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to add category. Try again later.';
      });
    builder
      .addCase(getAllDownloadGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(getAllDownloadGroup.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch documents. Try again later.';
      })
      .addCase(getAllDownloadGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.downloadCenterGroup = action.payload;
        state.pagination = action.payload.pagination;
      });
    builder
      .addCase(addDownloadCenterResource.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addDownloadCenterResource.fulfilled, (state, action) => {
        state.downloadCenterGroup = [
          action.payload,
          ...state.downloadCenterGroup,
        ];
        state.loading = false;
        state.error = null;
      })
      .addCase(addDownloadCenterResource.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to add category. Try again later.';
      });
    builder
      .addCase(getAllDownloadResources.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllDownloadResources.fulfilled, (state, action) => {
        state.downloadCenterResource = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllDownloadResources.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch resources. Try again later.';
      });
  },
});

export const downloadCenterReducer = DownloadcenterSlice.reducer;
export const { clearDownloadCenterError } = DownloadcenterSlice.actions;
