import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { localStorageKeys } from '@/constants';
import { RootState, useAppDispatch } from '@/store';
import { addAuth, removeAuth, updateAuth as setAuth } from '@/store/auth';
import { setAuthorizationHeader, setRegionHeader } from '@/utils/apiHelpers';

export type AuthType = {
  createdAt: string;
  createdBy: string;
  email: string;
  firstName: string;
  isPasswordResetRequired: boolean;
  lastName: string;
  loginVerified: boolean;
  role: string;
  twoFactorEnabled: boolean;
  updatedAt: string;
  __v: number;
  _id: string;
} | null;

export const RootContext = createContext<{
  auth: AuthType;
  setAuth: React.Dispatch<React.SetStateAction<AuthType>>;
  clearAuth: () => void;
  updateAuth: (data: AuthType) => void;
  region?: string;
  setRegion: (region?: string) => void;
  setHeaderTitle: (title?: React.ReactNode | string) => void;
  headerTitle?: React.ReactNode | string | null;
}>({
  auth: null,
  setAuth: () => {},
  clearAuth: () => {},
  updateAuth: () => {},
  setRegion: () => {},
  setHeaderTitle: () => {},
  headerTitle: null,
});

type RootProviderProps = {
  children: React.ReactNode;
};
export const RootProvider = ({ children }: RootProviderProps) => {
  const [isReady, setReady] = useState(false);
  const [region, setRegion] = useState();
  const [title, setTitle] = useState<React.ReactNode | string | null>(null);

  const dispatch = useAppDispatch();
  const auth = useSelector((state: RootState) => state.auth.user);

  const initAuth = async () => {
    const token = localStorage.getItem(localStorageKeys.accessToken);
    const userStrings = localStorage.getItem(localStorageKeys.user);

    const user = userStrings && JSON.parse(userStrings);
    if (token) {
      setAuthorizationHeader(token);
    }
    if (user?._id) {
      dispatch(addAuth(user));
    }
    setReady(true);
  };

  const updateAuth = (data: AuthType) => {
    const updatedData = { ...auth, ...data } as AuthType;

    dispatch(setAuth(updatedData));
    localStorage.setItem(localStorageKeys.user, JSON.stringify(updatedData));
  };

  useEffect(() => {
    initAuth();
  }, []);

  const clearAuth = () => {
    dispatch(removeAuth());
    localStorage.removeItem(localStorageKeys.user);
    localStorage.removeItem(localStorageKeys.accessToken);
  };

  return (
    <RootContext.Provider
      value={{
        auth,
        setAuth,
        clearAuth,
        updateAuth,
        region,
        headerTitle: title,
        setRegion: (val) => {
          setRegionHeader(val);
          setRegion(val);
        },
        setHeaderTitle: setTitle,
      }}
    >
      {isReady ? children : null}
    </RootContext.Provider>
  );
};

export const useRoot = () => useContext(RootContext);

export default RootProvider;
