import { createAsyncThunk } from '@reduxjs/toolkit';

import downloadCenter, {
  CategoryType,
  PaginationType,
  ResourceType,
} from '@/apiConfigs/downloadCenter';
import requestAPI from '@/utils/requestAPI';

export const addDownloadCenterCategory = createAsyncThunk(
  'downloadCenterCategory/addDownloadCenterCategory',
  async (category: CategoryType, { rejectWithValue }) => {
    try {
      const response = await requestAPI(
        downloadCenter.addDownloadCenterCategory(category)
      );

      return response;
    } catch (error: any) {
      console.log(error?.message, 'error');
      return rejectWithValue(error?.message || 'An error occurred');
    }
  }
);
export const getAllDownloadCategory = createAsyncThunk(
  'downloadCenterCategory/getDownloadCenterCategory',
  async (params: PaginationType) => {
    try {
      console.log('Fetching all products');
      const response = await requestAPI(
        downloadCenter.getDownloadCenterCategory(params)
      );
      console.log('API response:', response);
      return response;
    } catch (error: any) {
      console.error('Error fetching products:', error);
    }
  }
);

export const addDownloadCenterGroup = createAsyncThunk(
  'downloadCenterGroup/addDownloadCenterGroup',
  async (category: CategoryType, { rejectWithValue }) => {
    try {
      const response = await requestAPI(
        downloadCenter.addDownloadCenterGroup(category)
      );
      return response;
    } catch (error: any) {
      console.log(error, 'error');
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

export const getAllDownloadGroup = createAsyncThunk(
  'downloadCenterGroup/getDownloadCenterGroup',
  async (params: PaginationType) => {
    try {
      const response = await requestAPI(
        downloadCenter.getDownloadCenterGroup(params)
      );
      console.log('API response:', response);
      return response.data;
    } catch (error: any) {
      console.error('Error fetching products:', error);
    }
  }
);

export const addDownloadCenterResource = createAsyncThunk(
  'downloadCenterResource/addDownloadCenterResource',
  async (category: ResourceType, { rejectWithValue }) => {
    try {
      console.log(category, 'resources category');

      const response = await requestAPI(
        downloadCenter.addDownloadCenterResource(category)
      );
      return response;
    } catch (error: any) {
      console.log(error, 'error');
      return rejectWithValue(error.response?.error || 'An error occurred');
    }
  }
);

export const getAllDownloadResources = createAsyncThunk(
  'downloadCenterResource/getDownloadCenterResource',
  async (params: PaginationType) => {
    try {
      const response = await requestAPI(
        downloadCenter.getDownloadCenterResources(params)
      );
      return response.data;
    } catch (error: any) {
      console.error('Error fetching Resources:', error);
    }
  }
);
