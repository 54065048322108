import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

const ChartContainer = styled.div`
  width: 100%;
  height: 340px;
  margin: 0px;
`;

const Charts = () => {
  const data = [
    {
      name: 'Jan',
      PageView: 300,
      ProductLinkClicked: 400,
      amt: 7400,
    },
    {
      name: 'Feb',
      PageView: 800,
      ProductLinkClicked: 100,
      amt: 2400,
    },
    {
      name: 'Mar',
      PageView: 900,
      ProductLinkClicked: 200,
      amt: 1890,
    },
    {
      name: 'Apr',
      PageView: 270,
      ProductLinkClicked: 398,
      amt: 2000,
    },
    {
      name: 'May',
      PageView: 190,
      ProductLinkClicked: 800,
      amt: 2181,
    },
    {
      name: 'Jun',
      PageView: 390,
      ProductLinkClicked: 800,
      amt: 2500,
    },
    {
      name: 'July',
      PageView: 390,
      ProductLinkClicked: 300,
      amt: 2100,
    },
    {
      name: 'Aug',
      PageView: 490,
      ProductLinkClicked: 400,
      amt: 2100,
    },
    {
      name: 'Sep',
      PageView: 990,
      ProductLinkClicked: 70,
      amt: 2100,
    },
    {
      name: 'Oct',
      PageView: 340,
      ProductLinkClicked: 400,
      amt: 2100,
    },
    {
      name: 'Nov',
      PageView: 490,
      ProductLinkClicked: 300,
      amt: 2100,
    },
    {
      name: 'Dec',
      PageView: 3490,
      ProductLinkClicked: 4300,
      amt: 2100,
    },
  ];

  return (
    <ChartContainer>
      <ResponsiveContainer width={'100%'} height={'100%'}>
        <LineChart
          width={400}
          height={400}
          data={data}
          margin={{
            top: 30,
            right: 0,
            left: 0,
          }}
        >
          <Line
            type="monotone"
            dataKey="PageView"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="ProductLinkClicked" stroke="#82ca9d" />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 1000]} allowDataOverflow />
          <Tooltip />
          <Legend />
        </LineChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default Charts;
