/* eslint-disable */

import './style.css';
import './theme.css';
import './icons.css';

import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { OverflowNode } from '@lexical/overflow';
// PLUGINS------
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { ClickableLinkPlugin } from '@lexical/react/LexicalClickableLinkPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
// NODES--------
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { EditorState } from 'lexical';
import React, { ReactNode, useEffect, useState } from 'react';

import { EmojiNode } from './nodes/EmojiNode';
import { FigmaNode } from './nodes/FigmaNode';
import { ImageNode } from './nodes/ImageNode';
import { InlineImageNode } from './nodes/InlineImageNode/InlineImageNode';
import { KeywordNode } from './nodes/KeywordNode';
import { LayoutContainerNode } from './nodes/LayoutContainerNode';
import { LayoutItemNode } from './nodes/LayoutItemNode';
import { StyledTextNode } from './nodes/TextNode';
import { TweetNode } from './nodes/TweetNode';
import { YouTubeNode } from './nodes/YouTubeNode';
import AutoEmbedPlugin from './plugins/AutoEmbedPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import CustomOnChangePlugin from './plugins/CustomOnChangePlugin';
import DragDropPastePlugin from './plugins/DragDropPastePlugin';
import EmojisPlugin from './plugins/EmojisPlugin';
import FigmaPlugin from './plugins/FigmaPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin';
import ImagesPlugin from './plugins/ImagesPlugin';
import InlineImagePlugin from './plugins/InlineImagePlugin';
import KeywordsPlugin from './plugins/KeywordsPlugin';
import { LayoutPlugin } from './plugins/LayoutPlugin/LayoutPlugin';
import { SetInitialValuePlugin } from './plugins/SetInitialValuePlugin';
import TableActionMenuPlugin from './plugins/TableActionMenuPlugin';
import TableCellResizerPlugin from './plugins/TableCellResizer';
import ToolbarPlugin from './plugins/ToolbarPlugins';
import TwitterPlugin from './plugins/TwitterPlugin';
import YouTubePlugin from './plugins/YouTubePlugin';
import { editorTheme } from './theme';
import { ProductPlugin } from './plugins/DeepTagPlugin';
import ProductNode from './nodes/DeepTagNode';
import CopyPlugin from './plugins/CopyPlugin';

// -------

const onError = (error: any) => {
  console.error(error);
};

const TextEditor = ({
  editorState: localEditorState,
  setEditorState: setLocalEditorState,
  readOnly = false,
  keywords,
  keywordsInfos = {},
  initialHtml,
  deepTagPopup,
  enableAutofocus = false,
}: {
  editorState?: EditorState;
  setEditorState?: (data: { editorState: EditorState; html: string }) => void;
  readOnly?: boolean;
  keywords?: string[];
  keywordsInfos?: Record<string, any>;
  initialHtml?: string;
  deepTagPopup?: React.FC<any>;
  enableAutofocus?: boolean;
}) => {
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const initialConfig = {
    namespace: 'CustomizedEditor',
    theme: editorTheme,
    onError,
    editorState: localEditorState
      ? `${JSON.stringify(localEditorState)}`
      : `{
    "root": {
        "children": [
            {
                "children": [],
                "direction": null,
                "format": "",
                "indent": 0,
                "type": "paragraph",
                "version": 1,
                "textFormat": 0
            }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
    }
}`,
    editable: !readOnly,
    nodes: [
      HeadingNode,
      QuoteNode,
      ListNode,
      ListItemNode,
      AutoLinkNode,
      LinkNode,
      CodeHighlightNode,
      CodeNode,
      OverflowNode,
      HorizontalRuleNode,
      TableCellNode,
      TableNode,
      TableRowNode,
      LayoutContainerNode,
      LayoutItemNode,
      ImageNode,
      InlineImageNode,
      EmojiNode,
      KeywordNode,
      YouTubeNode,
      TweetNode,
      FigmaNode,
      StyledTextNode,
      ProductNode,
    ],
  };

  const handleEditorChange = (data: {
    editorState: EditorState;
    html: string;
  }) => {
    if (setLocalEditorState) {
      setLocalEditorState(data);
    }
  };
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <div className={`editor-container ${isFullScreen ? 'full-screen' : ''}`}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          className="fullscreen-toggle"
          onClick={toggleFullScreen}
          type="button"
          aria-label={isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
        >
          {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
        </button>
      </div>
      <LexicalComposer initialConfig={initialConfig}>
        {initialHtml && <SetInitialValuePlugin initHtml={initialHtml} />}

        {!readOnly && <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} />}
        <div className="editor-wrapper">
          <RichTextPlugin
            contentEditable={
              <div className="editor" ref={onRef}>
                <ContentEditable
                  className={`contentEditable ${readOnly ? '' : 'editable'} ${isFullScreen ? 'full-screen' : ''}`}
                />
              </div>
            }
            placeholder={
              <div className="placeholder">
                {readOnly
                  ? 'Please wait loading documents ...'
                  : 'Enter some text...'}
              </div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <ListPlugin />
          <CopyPlugin />
          <CheckListPlugin />
          <CodeHighlightPlugin />
          {/* <OnChangePlugin onChange={handleEditorChange} /> */}
          <CustomOnChangePlugin onChange={handleEditorChange} />
          <HistoryPlugin />
          {enableAutofocus && <AutoFocusPlugin />}
          <LinkPlugin />
          <ClickableLinkPlugin disabled={!readOnly} />
          <HorizontalRulePlugin />
          <TabIndentationPlugin />
          <TablePlugin hasCellMerge hasCellBackgroundColor />
          <LayoutPlugin />
          <ImagesPlugin />
          <InlineImagePlugin />
          <DragDropPastePlugin />
          <EmojisPlugin />
          <AutoEmbedPlugin />
          <YouTubePlugin />
          <TwitterPlugin />
          <FigmaPlugin />
          {/* <Typography>Hell</Typography>, */}
          {keywords && (
            <KeywordsPlugin
              keywords={keywords}
              keywordInfos={keywordsInfos}
              popupComponent={deepTagPopup}
            />
          )}
          <ProductPlugin />
          {floatingAnchorElem && (
            <>
              <TableCellResizerPlugin />
              <TableActionMenuPlugin
                anchorElem={floatingAnchorElem}
                cellMerge={true}
              />
              <FloatingLinkEditorPlugin
                anchorElem={floatingAnchorElem}
                isLinkEditMode={isLinkEditMode}
                setIsLinkEditMode={setIsLinkEditMode}
              />
              {!readOnly && (
                <FloatingTextFormatToolbarPlugin
                  setIsLinkEditMode={setIsLinkEditMode}
                  anchorElem={floatingAnchorElem}
                />
              )}
            </>
          )}
        </div>
      </LexicalComposer>
    </div>
  );
};

export default TextEditor;
