import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PaginationType } from '@/apiConfigs/documents';
import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import { Table } from '@/components';
import DeleteModal from '@/components/DeleteModal';
import Pagination from '@/components/Pagination';
import { docsAnalyticsKeys } from '@/constants';
import Button from '@/designComponents/Button';
import { ButtonTab } from '@/designComponents/ButtonTab';
import Input from '@/designComponents/Input';
import { TableDataType } from '@/designComponents/Table';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import { RootState, useAppDispatch, useAppSelector } from '@/store';
import {
  deleteDocument,
  getAllDocumentWithAnalytics,
} from '@/store/documents/functions';
import { toCapitalCase } from '@/utils/conversion';
import { debounce } from '@/utils/debounce';

import { SearchInputWrapperStyled } from './style';

type Props = {};
export const regionMapping = {
  ie: 'Ireland',
  uk: 'United Kingdom',
};

const Documents = (_props: Props) => {
  const [cardKey, setCardKey] = useState<string | null>(null);
  const [apiError, setApiError] = useState('');

  const [paginationParams, setPaginationParams] = useState<PaginationType>({
    page: 1,
    limit: 10,
    search: '',
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const documentsState = useAppSelector((state: RootState) => state.documents);
  const [docId, _setDocId] = useState('');
  const { documents, loading, pagination, analytics } = documentsState;
  const fetchDocuments = (myParams: PaginationType) => {
    const noEmptyParams = Object.entries(myParams).reduce((acc, val) => {
      if (!val[1]) {
        return acc;
      }
      acc = { ...acc, [val[0]]: val[1] };
      return acc;
    }, {});
    dispatch(getAllDocumentWithAnalytics(noEmptyParams));
  };
  const delayedFetch = useCallback(debounce(fetchDocuments, 300), []);

  useEffect(() => {
    if (documentsState.error) {
      setApiError(documentsState.error);
    }
  }, [documentsState.error]);
  useEffect(() => {
    fetchDocuments(paginationParams);
  }, [dispatch]);

  const tableData: TableDataType = {
    headings: [
      { key: 'name', label: 'Name/Title' },
      { key: 'documentType', label: 'Document Type' },
      { key: 'region', label: 'Region' },
      { key: 'fullSlug', label: 'Full Slug' },
      { key: 'actions', label: 'Actions' },
    ],
    body: Array.isArray(documents)
      ? documents?.map((document) => ({
          id: document?._id,
          name: document?.title,
          documentType: toCapitalCase(document?.type),
          region: document?.region
            ?.map((region: string) => regionMapping[region] || region)
            .join(', '),
          fullSlug: document?.fullSlug,
          parentTitle: document?.documentGroupId?.title,
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="gray"
                radius="normal"
                onClick={() => navigate(document._id)}
              >
                View
              </Button>
              {/* <Button
                variant="danger"
                radius="normal"
                onClick={() => {
                  setDocId(document._id);
                  setOpenDeleteModal(true);
                }}
              >
                Delete
              </Button> */}
            </div>
          ),
        }))
      : [],
  };

  const handleRowClick = (id: string, record: any) => {
    console.log('Row clicked:', id, record);
  };

  const updateParamsAndFetchDocuments = (updatedPagination: PaginationType) => {
    setPaginationParams(updatedPagination);
    delayedFetch({
      ...updatedPagination,
      search: updatedPagination.search.trim(),
    });
  };

  const handleCurrentPage = (page: number) => {
    const updatedPagination = { ...paginationParams, page: page };
    updateParamsAndFetchDocuments(updatedPagination);
  };

  const handleLimitChange = (limit: number) => {
    const updatedPagination = { ...paginationParams, page: 1, limit };
    updateParamsAndFetchDocuments(updatedPagination);
  };

  const onDocumentsTypeChange = (key: string) => {
    setCardKey(key);
    const updatedPagination = {
      ...paginationParams,
      page: 1,
      filter: { type: key },
    };
    updateParamsAndFetchDocuments(updatedPagination);
  };
  const onSearchValueChange = (value: string) => {
    const updatedPagination = {
      ...paginationParams,
      page: 1,
      search: value,
    };
    updateParamsAndFetchDocuments(updatedPagination);
  };

  const reducedAllCount = Object.values(analytics).reduce(
    (prev, curr: { key: string; value: string }) => {
      return (prev += curr.value);
    },
    0
  );

  const cardData = Object.keys(analytics).length
    ? docsAnalyticsKeys.map((ak) => ({
        title: toCapitalCase(ak),
        count: ak === 'all' ? reducedAllCount : analytics[ak]?.value || `${0}`,
        key:
          ak === 'go-green'
            ? 'go-green'
            : ak === 'regulations'
              ? 'Regulations'
              : ak === 'all'
                ? null
                : ak,
      }))
    : [];

  const tabData = (
    <ButtonTab
      items={cardData}
      activeIndex={cardData.findIndex((x) => x.key === cardKey)}
      setActiveIndex={(index) => onDocumentsTypeChange(cardData[index].key)}
    />
  );

  const handleDeleteDocument = () => {
    dispatch(deleteDocument(docId));
    if (!apiError) {
      setOpenDeleteModal(false);
      setPaginationParams((prev) => ({
        ...prev,
        page: 1,
      }));
      dispatch(getAllDocumentWithAnalytics({}));
    }
  };

  useHeaderTitle('Documents');

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <>
          {/* <div
            style={{
              display: 'flex',
              gap: '10px',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Button radius="md" onClick={() => setOpenCreateDocModal(true)}>
              New Document
            </Button>
            {openCreateDocModal && (
              <CreateDocuments
                isOpenModal={openCreateDocModal}
                onClose={() => setOpenCreateDocModal(false)}
              />
            )}
            <Button
              radius="md"
              onClick={() => setOpenCreateDocGroupModal(true)}
            >
              New Document Group
            </Button>
            {openCreateDocGroupModal && (
              <CreateDocumentGroup
                isOpen={openCreateDocGroupModal}
                onClose={() => setOpenCreateDocGroupModal(false)}
              />
            )}
          </div> */}
        </>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <SearchInputWrapperStyled>
            <Input
              value={paginationParams.search}
              onChange={(e) => {
                const { value } = e.target;
                onSearchValueChange(value);
              }}
              placeholder="Search Documents"
              iconLeft={<SearchSVG />}
            />
          </SearchInputWrapperStyled>
          {/* <ButtonTab
          items={cardData}
          activeIndex={cardData.findIndex((x) => x.key === cardKey)}
          setActiveIndex={(index) => onDocumentsTypeChange(cardData[index].key)}
        /> */}
        </div>

        <Table
          tableData={tableData}
          customRow={tabData}
          rowClick={handleRowClick}
          isLoading={loading}
          noData="No data to display."
          maxWidthForCardView="768px"
          headingBgColor="#f0f0f0"
          showMenuIcon={false}
          evenRowBgColor="#fafafa"
        />
        <Pagination
          activePage={paginationParams.page || 1}
          onCurrentPageChange={handleCurrentPage}
          totalPages={pagination?.totalPages || 1}
          limit={paginationParams.limit || 10}
          onLimitChange={handleLimitChange}
        />
      </div>

      <DeleteModal
        title="housebuild.com says"
        deleteText="Are you sure want to delete this document"
        isOpen={openDeleteModal}
        onConfirmDelete={handleDeleteDocument}
        confirmBtnLoading={documentsState.loading}
        onClose={() => setOpenDeleteModal(false)}
      />
    </>
  );
};

export default Documents;
