import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import HeadingTitle from '@/components/UserDetails/HeadingTitle';
import configs from '@/configs';
import BreadCrumb from '@/designComponents/BreadCrumb';
import Button from '@/designComponents/Button';
import Modal from '@/designComponents/Modal';
import Typography from '@/designComponents/Typography';
import { InformationRowWrapper } from '@/pages/Users/Details/style';
import { useAppDispatch, useAppSelector } from '@/store';
import { getSingleHowToVideos } from '@/store/videos/HowToVideos/functions';
import { toCapitalCase } from '@/utils/conversion';
import { humanReadableDate } from '@/utils/date';

import { howToVideosBreadCrumbData } from '../../data';
import AddHowToVideos from '../AddHowToVideos';
import { DetailsPageWrapper, InfoRowStyled } from '../style';

const HowToVideosDetails = () => {
  const { videoId } = useParams();
  const [openEditModal, setOpenEditModal] = useState(false);

  const { singleVideo } = useAppSelector((state) => state.howToVideos);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSingleHowToVideos(videoId || ''));
  }, [dispatch, videoId]);

  return (
    <DetailsPageWrapper>
      <div className="crumb-btn">
        <BreadCrumb
          breadCrumb={howToVideosBreadCrumbData(
            toCapitalCase(singleVideo?.title)
          )}
        />
        <div className="btn">
          <Button radius="md" onClick={() => setOpenEditModal(true)}>
            Edit Video
          </Button>

          {openEditModal && (
            <Modal
              isOpen={openEditModal}
              onClose={() => setOpenEditModal(false)}
              title="Edit How To Video"
            >
              <AddHowToVideos
                isModalOpen={openEditModal}
                setIsModalOpen={setOpenEditModal}
              />
            </Modal>
          )}
        </div>
      </div>
      <div className="main-content">
        <InfoRowStyled>
          <HeadingTitle
            title="How To Video"
            frontEndUrl={`${configs.frontendURL}/construction/how-to-videos/${singleVideo?.slug}`}
          />
          <InformationRowWrapper>
            <>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Cover Photo Preview
                </Typography>
                <img
                  src={singleVideo?.imageUrl}
                  alt="cover-photo"
                  width={90}
                  height={60}
                />
              </div>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Url
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {singleVideo?.link}
                </Typography>
              </div>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Title
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {singleVideo?.title}
                </Typography>
              </div>
              {/* <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Description
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {singleVideo?.description}
                </Typography>
              </div> */}
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Author
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {singleVideo?.author}
                </Typography>
              </div>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Category
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {singleVideo?.category.name}
                </Typography>
              </div>

              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Created At
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {humanReadableDate(singleVideo?.createdAt)}
                </Typography>
              </div>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Updated At
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {humanReadableDate(singleVideo?.updatedAt)}
                </Typography>
              </div>
            </>
          </InformationRowWrapper>
        </InfoRowStyled>
      </div>
    </DetailsPageWrapper>
  );
};

export default HowToVideosDetails;
