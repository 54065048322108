import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Table } from '@/components';
import Pagination from '@/components/Pagination';
import Button from '@/designComponents/Button';
import { TableDataType } from '@/designComponents/Table';
import { debounce } from '@/pages/DownloadCenter';
import { useAppDispatch, useAppSelector } from '@/store';
import { getAllHowToVideosCategoryClass } from '@/store/videos/HowToVideosCategoryClass/functions';
import { humanReadableDate } from '@/utils/date';

type Props = {
  downloadCenterTabs: ReactNode;
};
const CategoryClassTable = ({ downloadCenterTabs }: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, _setSearch] = useState('');
  const navigate = useNavigate();

  const { videos } = useAppSelector((state) => state.howToVideosCategoryClass);
  const dispatch = useAppDispatch();

  const tableData: TableDataType = {
    headings: [
      { key: 'name', label: 'Name' },
      { key: 'createdAt', label: 'Created At' },
      { key: 'updatedAt', label: 'Updated At' },
      { key: 'actions', label: 'Actions' },
    ],
    body: videos?.data
      ? videos?.data.map((video) => ({
          id: `${video._id}`,
          name: video.name,
          createdAt: humanReadableDate(video.createdAt),
          updatedAt: humanReadableDate(video.updatedAt),
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="gray"
                radius="normal"
                onClick={() => navigate(video._id)}
              >
                View
              </Button>
            </div>
          ),
        }))
      : [],
  };

  const fetchHowToVideos = useCallback(
    debounce((searchTerm) => {
      dispatch(
        getAllHowToVideosCategoryClass({
          limit,
          page: currentPage,
          search: searchTerm,
        })
      );
    }, 300),
    [dispatch, currentPage, limit]
  );

  useEffect(() => {
    fetchHowToVideos(search);
    return () => {
      fetchHowToVideos.cancel();
    };
  }, [fetchHowToVideos, search, currentPage]);

  const handleCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleNextPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleLastPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleLimitChange = (count: number) => {
    setLimit(count);
  };

  return (
    <>
      <Table
        tableData={tableData}
        // isLoading={loading}
        noData="No data to display."
        maxWidthForCardView="768px"
        headingBgColor="#f0f0f0"
        showMenuIcon={false}
        evenRowBgColor="#fafafa"
        customRow={downloadCenterTabs}
      />
      <Pagination
        activePage={currentPage}
        onCurrentPageChange={handleCurrentPage}
        totalPages={videos?.pagination?.totalPages || 10}
        limit={limit}
        onLimitChange={handleLimitChange}
        onNext={handleNextPage}
        onLast={handleLastPage}
      />
    </>
  );
};

export default CategoryClassTable;
