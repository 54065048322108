import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import { Table } from '@/components';
import Pagination from '@/components/Pagination';
import { useRoot } from '@/context/RootProvider';
import Button from '@/designComponents/Button';
import { ButtonTab } from '@/designComponents/ButtonTab';
import Input from '@/designComponents/Input';
import RadioButton from '@/designComponents/Radio';
import { TableDataType } from '@/designComponents/Table';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import { useAppDispatch, useAppSelector } from '@/store';
import { getAllStories } from '@/store/stories/function';
import { humanReadableDate } from '@/utils/date';
import { debounce } from '@/utils/debounce';

import { regionMapping } from '../Documents';
import CreateStories from './CreateStories';
import { storiesCardData, StoryCardKey } from './data';
import Filter from './Filter';
import { SearchInputWrapperStyled } from './style';

type Props = {};

const Stories = (_props: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const dispatch = useAppDispatch();
  const { region: countryRegion } = useRoot();
  const stories = useAppSelector((state) => state.stories.stories);
  const navigate = useNavigate();
  const [cardKey, setCardKey] = useState<StoryCardKey>('all');
  const [approvedOption, setApprovedOption] = useState('all');
  const approvedMapping: { [key: string]: boolean } = {
    approved: true,
    'not-approved': false,
  };
  const fetchStoriesWhenPageChange = useCallback(
    debounce((searchTerm: string) => {
      dispatch(
        getAllStories({
          page: currentPage,
          limit,
          search: searchTerm ? searchTerm : null,
          filter: {
            approved: approvedMapping[approvedOption] ?? null,
          },
          type: cardKey === 'all' ? null : cardKey,
        })
      );
    }, 300),
    [currentPage, limit, dispatch, approvedOption, cardKey]
  );

  useEffect(() => {
    fetchStoriesWhenPageChange(search);
  }, [search, fetchStoriesWhenPageChange, currentPage, countryRegion]);

  const tableData: TableDataType = {
    headings: [
      { key: 'title', label: 'Title' },
      { key: 'status', label: 'Status' },
      { key: 'type', label: 'Type' },
      { key: 'region', label: 'Region' },
      { key: 'slug', label: 'Slug' },
      { key: 'createdAt', label: 'Created At' },
      { key: 'updatedAt', label: 'Updated At' },
      { key: 'actions', label: 'Actions' },
    ],
    body: stories?.data
      ? stories?.data?.map((story) => ({
          id: `${story._id}`,
          title: story.title,
          status: story.approved === true ? 'Approved' : 'Not Approved',
          type: story.type.join(', '),
          region: story?.region
            .map((region) => regionMapping[region] || region)
            .join(', '),
          slug: story.slug,
          createdAt: humanReadableDate(story.createdAt),
          updatedAt: humanReadableDate(story.updatedAt),
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="gray"
                radius="normal"
                onClick={() => navigate(story._id)}
              >
                View
              </Button>
            </div>
          ),
        }))
      : [],
  };

  const handleRadioClick = ({ target: { value } }) => {
    setApprovedOption(value);
  };

  const handleCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleLimitChange = (count: number) => {
    setLimit(count);
  };

  const handleClick = (key: StoryCardKey) => {
    setCardKey(key);
    if (currentPage > 1) {
      setCurrentPage(1);
    }
  };

  useHeaderTitle('Stories');

  const counts = {
    totalCount: stories?.analytics.noOfStories + stories?.analytics.noOfIdeas,
    totalIdeas: stories?.analytics.noOfIdeas,
    totalStories: stories?.analytics.noOfStories,
  };
  return (
    <React.Fragment>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
        <div
          style={{
            display: 'flex',
            gap: '10px',
            justifyContent: 'space-between',
          }}
        >
          <SearchInputWrapperStyled>
            <Input
              value={search}
              onChange={(e) => {
                const { value } = e.target;
                setSearch(value);
              }}
              placeholder="Search"
              iconLeft={<SearchSVG />}
            />
          </SearchInputWrapperStyled>
          <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
            <Filter
              filterContentChildren={
                <RadioButton
                  options={[
                    {
                      label: 'All',
                      value: 'all',
                    },
                    {
                      label: 'Approved',
                      value: 'approved',
                    },
                    {
                      label: 'Not Approved',
                      value: 'not-approved',
                    },
                  ]}
                  onChange={handleRadioClick}
                  selctedOption={approvedOption}
                />
              }
            />
            <CreateStories />
          </div>
        </div>
        <>
          <Table
            tableData={tableData}
            customRow={
              <div style={{ height: '50px' }}>
                <ButtonTab
                  items={storiesCardData(counts)}
                  activeIndex={storiesCardData(counts).findIndex(
                    (x) => x.key === cardKey
                  )}
                  setActiveIndex={(index) =>
                    handleClick(storiesCardData(counts)[index].key)
                  }
                />
              </div>
            }
            // isLoading={loading}
            noData="No data to display."
            maxWidthForCardView="768px"
            headingBgColor="#f0f0f0"
            showMenuIcon={false}
            evenRowBgColor="#fafafa"
          />
          <Pagination
            activePage={currentPage}
            onCurrentPageChange={handleCurrentPage}
            totalPages={stories?.pagination?.totalPages || 10}
            limit={limit}
            onLimitChange={handleLimitChange}
          />
        </>
      </div>
    </React.Fragment>
  );
};

export default Stories;
