import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PaginationType } from '@/apiConfigs/ads';
import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import { Table } from '@/components';
import Pagination from '@/components/Pagination';
import Button from '@/designComponents/Button';
import { ButtonTab } from '@/designComponents/ButtonTab';
import Input from '@/designComponents/Input';
import { TableDataType } from '@/designComponents/Table';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import { RootState, useAppDispatch } from '@/store';
import { getAllAds } from '@/store/advertise/functions';
import { humanReadableDate } from '@/utils/date';
import { debounce } from '@/utils/debounce';

import { SearchInputWrapperStyled } from '../Documents/style';
import Advertise from './Advertise';
import AdvertisementModal from './AdvertisementModal';

type Props = {};

export const cardData = [
  {
    title: 'Ads',
    key: 'ads',
  },
  {
    title: 'Advertiser',
    key: 'advertise',
  },
];

const Advertisement = (_props: Props) => {
  const [cardKey, setCardKey] = useState<string>('ads');
  const [searchTerm, setSearchTerm] = useState('');

  const [paginationParams, setPaginationParams] = useState<PaginationType>({
    page: 1,
    limit: 10,
    search: '',
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const ads = useSelector((state: RootState) => state.ads.ads);
  const pagination = useSelector((state: RootState) => state.ads.pagination);

  const handleClick = (key: string) => {
    setCardKey(key);
  };

  useHeaderTitle('Advertisements');

  const tableData: TableDataType = {
    headings: [
      { key: 'image', label: 'image' },
      { key: 'name', label: 'Name' },

      { key: 'date', label: 'Updated At' },
      { key: 'actions', label: 'Actions' },
    ],
    body: Array.isArray(ads)
      ? ads.map((ad) => ({
          id: ad?.id,
          image: (
            <img
              src={ad?.content}
              style={{ height: '100px', width: '100px' }}
            />
          ),
          name: ad?.name,
          date: humanReadableDate(ad?.updatedAt),
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="gray"
                radius="normal"
                onClick={() => navigate(ad._id)}
              >
                View
              </Button>
            </div>
          ),
        }))
      : [],
  };

  const fetchads = useCallback(() => {
    dispatch(getAllAds(paginationParams));
  }, [dispatch, paginationParams]);

  useEffect(() => {
    fetchads();
  }, [dispatch, paginationParams]);

  const handleCurrentPage = (page: number) => {
    setPaginationParams((prev) => ({ ...prev, page }));
  };

  const handleLimitChange = (limit: number) => {
    setPaginationParams((prev) => ({ ...prev, page: 1, limit }));
  };

  const handleNextPage = (page) => {
    setPaginationParams({ ...paginationParams, page: page });
  };

  const handleLastPage = (page: number) => {
    setPaginationParams({ ...paginationParams, page });
  };

  const debouncedSearch = useCallback(
    debounce((term: string) => {
      setPaginationParams((prev) => ({
        ...prev,
        search: term,
        page: 1,
      }));
    }, 300),
    []
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const advertiseTabs = (
    <ButtonTab
      items={cardData}
      activeIndex={cardData.findIndex((x) => x.key === cardKey)}
      setActiveIndex={(index) => handleClick(cardData[index].key)}
    />
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <SearchInputWrapperStyled>
          <Input
            value={searchTerm}
            onChange={handleInputChange}
            placeholder={cardKey === 'ads' ? 'Search Ads' : 'Search Advertiser'}
            iconLeft={<SearchSVG />}
          />
        </SearchInputWrapperStyled>
        <AdvertisementModal />
      </div>
      {cardKey === 'advertise' ? (
        <Advertise advertiseTabs={advertiseTabs} search={searchTerm} />
      ) : (
        <>
          <Table
            customRow={advertiseTabs}
            tableData={tableData}
            noData="No data to display."
            headingBgColor="#f0f0f0"
            showMenuIcon={false}
            evenRowBgColor="#fafafa"
          />

          <Pagination
            activePage={paginationParams.page || 1}
            onCurrentPageChange={handleCurrentPage}
            onLast={handleLastPage}
            onNext={handleNextPage}
            totalPages={pagination?.totalPages}
            limit={paginationParams.limit || 10}
            onLimitChange={handleLimitChange}
          />
        </>
      )}
    </div>
  );
};

export default Advertisement;
