import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import tags from '@/apiConfigs/tag';
import Button from '@/designComponents/Button';
import Typography from '@/designComponents/Typography';
import theme from '@/theme';
import requestAPI from '@/utils/requestAPI';

import AddTag from '../CreateTag';
import { AdvertiseDetailsWrapper } from './style';

type TagsDetailsType = {
  title: string;
  category: {
    title: string;
  };
  subCategory: [
    {
      _id: string;
      title: string;
    },
  ];
  products: [
    {
      _id: string;
      title: string;
    },
  ];
};

const TagDetails = () => {
  const { tagId } = useParams();
  const [tag, setTag] = useState<TagsDetailsType>({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const getSingleTag = async (id: string) => {
    try {
      const data = await requestAPI(tags.getSingleTag(id));
      setTag(data);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(tag);

  useEffect(() => {
    if (tagId) {
      getSingleTag(tagId);
    }
  }, []);

  return (
    <AdvertiseDetailsWrapper>
      <div className="main-content">
        <div className="heading">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              fontFamily="jost"
              fontWeight="medium"
              size="heading"
              color="dark.neutral_60"
            >
              Tags Details
            </Typography>
            <Button
              variant="primary"
              radius="normal"
              onClick={() => setIsEditModalOpen(true)}
            >
              Edit Tag
            </Button>
            <AddTag
              isOpenModal={isEditModalOpen}
              onClose={() => setIsEditModalOpen(false)}
            />
          </div>

          <hr
            style={{
              backgroundColor: theme.colors.dark.neutral_50,
              border: 'none',
              height: '1px',
            }}
          />
        </div>
        <div className="main-text">
          <div className="text">
            <Typography className="text-value">Tag Title</Typography>
            <Typography className="text-value">{tag?.title}</Typography>
          </div>

          <div className="text">
            <Typography className="text-value">Category Title</Typography>
            <Typography className="text-value">
              {tag?.category?.title}
            </Typography>
          </div>

          <div>
            <Typography
              size="h5"
              fontWeight="bold"
              style={{ marginBottom: '10px' }}
            >
              Sub Categories
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              {tag?.subCategory?.map((sub) => {
                return (
                  <div key={sub._id}>
                    {' '}
                    <li>{sub.title}</li>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <Typography
              size="h5"
              fontWeight="bold"
              style={{ marginBottom: '10px' }}
            >
              Products
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              {tag.products?.map((product) => {
                return (
                  <div key={product?._id}>
                    <li>{product.title}</li>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </AdvertiseDetailsWrapper>
  );
};

export default TagDetails;
