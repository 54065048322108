import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { PaginationType } from '@/apiConfigs/documents';
import { hashTagApi } from '@/apiConfigs/hashTag';
import requestAPI from '@/utils/requestAPI';

export const getHashTags = createAsyncThunk(
  '/hash-tags',
  async (params: PaginationType, { rejectWithValue }) => {
    try {
      const response = await requestAPI(hashTagApi.getAllHashTags(params));
      return response;
    } catch (error: any) {
      console.error(error.message, 'error from catch');
      toast.error(error.message);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);
