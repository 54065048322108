import { ConfigType } from '@/utils/requestAPI';

export type PaginationType = {
  limit?: number;
  total?: number;
  totalPages?: number;
  currentPage?: number;
  page?: number;
  search?: string;
  type?: 'SHOWROOM' | 'CONSTRUCTION' | 'BUILDING_MATERIAL';
  isFeatured?: boolean | null;
};

export type ProductMapPaginationType = {
  limit?: number;
  total?: number;
  totalPages?: number;
  currentPage?: number;
  page?: number;
  search?: string;
};

export type ProductType = {
  title: string;
  description: string;
  specification: {
    contentType: string;
    content: string;
  };
  price: number;
  vat: number;
  supplierId: string;
  publishedAt: string;
  externalId?: string;
  stock: number;
  isDeliverable: boolean;
  benefits?: string;
  region: string[];
  photos: string[];
  referralLink?: string;
  video?: string;
  type: string;
  featured?: boolean;
  // status: string;
  icon?: File[] | null;
};

export type ProductMapperType = {
  externalCategory: string;
  internalCategory: string;
};
const products = {
  addproduct: (data: ProductType): ConfigType => {
    return {
      method: 'post',
      url: '/product',
      data,
    };
  },
  updateProduct: (data: ProductType, id: string): ConfigType => {
    return {
      method: 'put',
      url: `/product/${id}`,
      data,
    };
  },

  getAllProduct: (params?: PaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/product',
      params,
    };
  },
  getSingleProduct: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/product/${id}`,
    };
  },
  deleteSingleProduct: (id: string): ConfigType => {
    return {
      method: 'delete',
      url: `/product/${id}`,
    };
  },

  addProductMapper: (data: ProductMapperType): ConfigType => {
    return {
      method: 'post',
      url: '/product-maps',
      data,
    };
  },
  updateProductMapper: (id: string, data: ProductMapperType): ConfigType => {
    return {
      method: 'put',
      url: `/product-maps/${id}`,
      data,
    };
  },

  getProductMaps: (params?: ProductMapPaginationType): ConfigType => {
    return {
      method: 'get',
      url: '/product-maps',
      params,
    };
  },

  getProductCategory: (): ConfigType => {
    return {
      method: 'get',
      url: '/product-category',
    };
  },
};

export default products;
