import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import products from '@/apiConfigs/products';
import tags, { TagType } from '@/apiConfigs/tag';
import CountrySelection from '@/components/CountrySelection';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Modal from '@/designComponents/Modal';
import { ModalContentWrapper } from '@/designComponents/Modal/style';
import MultiSelect from '@/designComponents/MultiSelect';
import Select from '@/designComponents/Select';
import Typography from '@/designComponents/Typography';
import requestAPI from '@/utils/requestAPI';

type InitialValueType = {
  title?: string;
  category?: string;
  subCategory?: string[];
  products?: string[];
  region?: string;
};

type Props = {
  isOpenModal: boolean;
  onClose: () => void;
};

const AddTag = ({ isOpenModal, onClose }: Props) => {
  const formikRef = useRef<FormikProps<InitialValueType>>(null);
  const { tagId } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('ie');
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [tagProducts, setTagProducts] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [initialValues, setInitialValues] = useState<InitialValueType>({
    title: '',
    category: '',
    subCategory: [],
    products: [],
    region: selectedCountry,
  });

  const getDocumentGroup = async (level = 0, parentId = '') => {
    try {
      const params = {
        'filter[level]': level,
        ...(parentId && { 'filter[parentId]': parentId }),
      };
      const data = await requestAPI(tags.getCategories(params));
      return data.data;
    } catch (error) {
      console.error('Error fetching document groups', error);
      return [];
    }
  };
  const handleCategorySelect = async (
    categoryId: string,
    isInitial?: boolean
  ) => {
    if (formikRef.current && !isInitial) {
      formikRef?.current?.setFieldValue('subCategory', []);
    }
    const fetchedSubCategories = await getDocumentGroup(1, categoryId);
    setSubCategories(fetchedSubCategories);
  };

  const fetchTagDetails = async () => {
    if (!tagId) return;
    try {
      const data = await requestAPI(tags.getSingleTag(tagId));
      setInitialValues({
        title: data?.title,
        category: data?.category?._id,
        subCategory: data?.subCategory?.map((sub: any) => sub._id),
        products: data?.products?.map((prod: any) => prod._id),
        region: data?.region,
      });
      await handleCategorySelect(data?.category?._id, true);
      setSelectedCountry(data?.region || 'ie');
    } catch (error) {
      console.error('Error fetching tag details', error);
    }
  };

  const getProducts = async () => {
    try {
      const data = await requestAPI(products.getAllProduct());
      setTagProducts(data.products.data);
    } catch (error) {
      console.error('Error fetching products', error);
    }
  };

  const handleAddTag = async (val: TagType) => {
    setLoading(true);
    try {
      await requestAPI(
        tags.addTag({
          title: val.title,
          category: val.category,
          subCategory: val.subCategory,
          products: val.products,
          region: val.region,
        })
      );
      toast.success('Tag added successfully');
      onClose();
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  const handleUpdateTag = async (val: TagType) => {
    setLoading(true);
    try {
      await requestAPI(
        tags.updateTag(tagId, {
          title: val.title,
          category: val.category,
          subCategory: val.subCategory,
          products: val.products,
          region: val.region,
        })
      );
      fetchTagDetails();
      setLoading(false);
      toast.success('Tag updated successfully');
      onClose();
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpenModal) {
      getProducts();
      getDocumentGroup(0).then(setCategories);
      if (tagId) {
        fetchTagDetails();
      }
    }
  }, [tagId, isOpenModal]);

  return (
    <Modal
      isOpen={isOpenModal}
      onClose={onClose}
      title={tagId ? 'Update Tag' : 'Add Tag'}
      halfWidth
    >
      <ModalContentWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={tagId ? handleUpdateTag : handleAddTag}
          enableReinitialize
          innerRef={formikRef}
        >
          {(formikProps) => {
            const { values, setFieldValue, errors, touched } = formikProps;
            return (
              <Form>
                <div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CountrySelection
                      selectedCountry={selectedCountry}
                      setSelectedCountry={setSelectedCountry}
                    />
                  </div>

                  <div style={{ marginBottom: '20px' }}>
                    <Typography
                      fontWeight="medium"
                      style={{ marginBottom: '10px' }}
                    >
                      Title
                    </Typography>
                    <Input
                      title="title"
                      value={values.title}
                      onChange={(e) => setFieldValue('title', e.target.value)}
                    />
                    {errors.title && touched.title ? (
                      <div style={{ color: 'red', marginTop: '10px' }}>
                        {errors.title}
                      </div>
                    ) : null}
                  </div>

                  <div style={{ marginBottom: '20px' }}>
                    <Typography
                      fontWeight="medium"
                      style={{ marginBottom: '5px' }}
                    >
                      Categories*
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '15px',
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <Typography
                          fontWeight="medium"
                          style={{ marginBottom: '10px', color: 'gray' }}
                        >
                          Category
                        </Typography>
                        <Select
                          name="category"
                          selected={values.category}
                          onSelect={(value) => {
                            setFieldValue('category', value);
                            handleCategorySelect(value);
                          }}
                          options={categories.map((cat) => ({
                            label: cat.title,
                            value: cat._id,
                          }))}
                          placeholder="select category"
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <Typography
                          fontWeight="medium"
                          style={{ marginBottom: '10px', color: 'gray' }}
                        >
                          Sub Category
                        </Typography>
                        <MultiSelect
                          name="subCategory"
                          options={subCategories.map((sub) => ({
                            label: sub.title,
                            value: sub._id,
                          }))}
                          formikProps={formikProps}
                          placeholder="Select subcategory"
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ marginBottom: '20px' }}>
                    <Typography
                      fontWeight="medium"
                      style={{ marginBottom: '5px' }}
                    >
                      Products*
                    </Typography>
                    <MultiSelect
                      name="products"
                      options={tagProducts.map((prod) => ({
                        label: prod.title,
                        value: prod._id,
                      }))}
                      formikProps={formikProps}
                      placeholder="select products"
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '20px',
                      gap: '20px',
                    }}
                  >
                    <Button
                      variant="gray"
                      radius="normal"
                      type="button"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" isLoading={loading} radius="normal">
                      {tagId ? 'Update' : 'Save'}
                    </Button>
                  </div>
                </div>
                {errorMessage && (
                  <div style={{ color: 'red', marginTop: '10px' }}>
                    {errorMessage}
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </ModalContentWrapper>
    </Modal>
  );
};

export default AddTag;
