import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import HeadingTitle from '@/components/UserDetails/HeadingTitle';
import BreadCrumb from '@/designComponents/BreadCrumb';
import Button from '@/designComponents/Button';
import Modal from '@/designComponents/Modal';
import Typography from '@/designComponents/Typography';
import { InformationRowWrapper } from '@/pages/Users/Details/style';
import { useAppDispatch, useAppSelector } from '@/store';
import { getSingleHowToVideosCategory } from '@/store/videos/HowToVideosCategory/functions';
import { humanReadableDate } from '@/utils/date';

import { howToVideosCatBreadCrumbData } from '../../data';
import { DetailsPageWrapper, InfoRowStyled } from '../../HowToVideos/style';
import AddHowToVideosCategory from '../AddHowToVideosCategory';

const HowToVideosCategoryDetails = () => {
  const { videoId } = useParams();
  const [openEditModal, setOpenEditModal] = useState(false);

  const { singleVideo } = useAppSelector((state) => state.howToVideosCategory);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getSingleHowToVideosCategory(videoId || ''));
  }, [dispatch, videoId]);
  return (
    <DetailsPageWrapper>
      <div className="crumb-btn">
        <BreadCrumb
          breadCrumb={howToVideosCatBreadCrumbData(singleVideo?.name)}
        />
        <div className="btn">
          <Button radius="md" onClick={() => setOpenEditModal(true)}>
            Edit Video Category
          </Button>

          {openEditModal && (
            <Modal
              isOpen={openEditModal}
              onClose={() => setOpenEditModal(false)}
              title="Edit Video Category"
            >
              <AddHowToVideosCategory
                isModalOpen={openEditModal}
                setIsModalOpen={setOpenEditModal}
              />
            </Modal>
          )}
        </div>
      </div>
      <div className="main-content">
        <InfoRowStyled>
          <HeadingTitle title="How To Video Category Details" />
          <InformationRowWrapper>
            <>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  ID
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {singleVideo?._id}
                </Typography>
              </div>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Name
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {singleVideo?.name}
                </Typography>
              </div>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Position
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {singleVideo?.position}
                </Typography>
              </div>
              {/* <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Icon
                </Typography>
                <img
                  src={singleVideo?.icon}
                  alt="video-category-icon"
                  width={100}
                  height={50}
                />
              </div> */}
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Created At
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {humanReadableDate(singleVideo?.createdAt)}
                </Typography>
              </div>
              <div className="text">
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  Updated At
                </Typography>
                <Typography
                  fontFamily="roboto"
                  fontWeight="normal"
                  color="dark.neutral_450"
                  size="normal"
                >
                  {humanReadableDate(singleVideo?.updatedAt)}
                </Typography>
              </div>
            </>
          </InformationRowWrapper>
        </InfoRowStyled>
      </div>
    </DetailsPageWrapper>
  );
};

export default HowToVideosCategoryDetails;
