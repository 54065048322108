import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PaginationType } from '@/apiConfigs/products';
import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import { Table } from '@/components';
import Pagination from '@/components/Pagination';
import { productsAnalyticsKeys } from '@/constants';
import { useRoot } from '@/context/RootProvider';
import Button from '@/designComponents/Button';
import { ButtonTab } from '@/designComponents/ButtonTab';
import Input from '@/designComponents/Input';
import Modal from '@/designComponents/Modal';
import RadioButton from '@/designComponents/Radio';
import { TableDataType } from '@/designComponents/Table';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import { RootState, useAppDispatch } from '@/store';
import { getAllProducts } from '@/store/products/functions';
import { toCapitalCase, toSnakeUpperCase } from '@/utils/conversion';
import { debounce } from '@/utils/debounce';

import { regionMapping } from '../Documents';
import { SearchInputWrapperStyled } from '../Documents/style';
import Filter from '../Stories/Filter';
import CreateProducts from './CreateProduct';

const Ecommerce = () => {
  const [featuredOption, setFeaturedOption] = useState('all');
  const featuredMapping: { [key: string]: boolean | null } = {
    all: null,
    featured: true,
    'not-featured': false,
  };

  const [paginationParams, setPaginationParams] = useState<PaginationType>({
    page: 1,
    limit: 10,
    isFeatured: featuredMapping.all,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useAppDispatch();
  const { region } = useRoot();

  useHeaderTitle('Products');

  const [cardKey, setCardKey] = useState<string>('ALL');

  const productsState = useSelector((state: RootState) => state.products);
  const { products, pagination, loading, analytics } = productsState;

  const navigate = useNavigate();

  const fetchProducts = (myParams: PaginationType) => {
    const noEmptyParams = Object.entries(myParams).reduce((acc, val) => {
      if (!val[1]) {
        return acc;
      }
      acc = { ...acc, [val[0]]: val[1] };
      return acc;
    }, {});
    dispatch(getAllProducts(noEmptyParams));
  };
  const delayedFetch = useCallback(debounce(fetchProducts, 300), []);

  useEffect(() => {
    dispatch(getAllProducts(paginationParams));
  }, [dispatch, paginationParams, region]);

  const tableData: TableDataType = {
    headings: [
      { key: 'name', label: 'Product Name' },
      { key: 'productType', label: 'Types' },
      { key: 'region', label: 'Region' },
      { key: 'suppelierId', label: 'Supplier Id' },
      { key: 'status', label: 'Status' },
      { key: 'price', label: 'Price' },
      { key: 'actions', label: 'Actions' },
    ],
    body: Array.isArray(products)
      ? products?.map((product) => {
          return {
            id: product._id,
            name: product?.title,
            productType: product?.type,
            region: product.region.map((reg) => regionMapping[reg]).join(', '),
            suppelierId: product?.supplierId,
            status: product?.featured ? 'Featured' : 'Not Featured',
            price: product?.price,
            actions: (
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="gray"
                  radius="normal"
                  onClick={() => navigate(product?._id)}
                >
                  View
                </Button>
              </div>
            ),
          };
        })
      : [],
  };

  const updateParamsAndFetchProducts = (updatedPagination: PaginationType) => {
    setPaginationParams(updatedPagination);
    delayedFetch(updatedPagination);
  };

  const handleCurrentPage = (page: number) => {
    const updatedPagination = { ...paginationParams, page: page };
    updateParamsAndFetchProducts(updatedPagination);
  };

  const handleLimitChange = (limit: number) => {
    const updatedPagination = { ...paginationParams, page: 1, limit };
    updateParamsAndFetchProducts(updatedPagination);
  };

  const onProductsTypeChange = (key: string) => {
    setCardKey(key);
    const updatedPagination = {
      ...paginationParams,
      page: 1,
    };

    if (key === 'ALL') {
      delete updatedPagination.type;
    } else {
      updatedPagination.type = key;
    }

    updateParamsAndFetchProducts(updatedPagination);
  };

  const onSearchValueChange = (value: string) => {
    const updatedPagination = {
      ...paginationParams,
      page: 1,
      search: value,
    };
    updateParamsAndFetchProducts(updatedPagination);
  };

  const handleNextPage = () => {
    if (
      paginationParams.page &&
      paginationParams.totalPages &&
      paginationParams.page < paginationParams.totalPages
    ) {
      handleCurrentPage(paginationParams.page + 1);
    }
  };

  const handleLastPage = () => {
    if (
      pagination?.totalPages &&
      paginationParams.page !== pagination.totalPages
    ) {
      handleCurrentPage(pagination.totalPages);
    }
  };

  const cardData =
    analytics && Object.keys(analytics).length
      ? productsAnalyticsKeys.map((ak) => ({
          title: toCapitalCase(ak),
          count:
            ak === 'all'
              ? Object.values(analytics).reduce((acc, item) => {
                  acc += item;
                  return acc;
                }, 0)
              : analytics[ak] || 0,
          key: toSnakeUpperCase(ak).replace('_COUNT', ''),
        }))
      : [];

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const productTabs = (
    <ButtonTab
      items={cardData}
      activeIndex={cardData.findIndex((x) => x.key === cardKey)}
      setActiveIndex={(index) => onProductsTypeChange(cardData[index].key)}
    />
  );

  const handleFeaturedChange = (value: string) => {
    setFeaturedOption(value);
    const updatedPagination = {
      ...paginationParams,
      page: 1,
      ...paginationParams,
      isFeatured: featuredMapping[value],
    };
    updateParamsAndFetchProducts(updatedPagination);
  };

  // const counts = {
  //   totalCount = analytics?.buildingMaterialCount +
  //     analytics?.constructionCount +
  //     analytics?.showroomCount,
  //   totalBuildingMaterialCount = analytics?.buildingMaterialCount,
  //   totalConstructionCount = analytics?.constructionCount,
  //   totalShowroomCount = analytics?.showroomCount,
  // };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <SearchInputWrapperStyled>
            <Input
              value={paginationParams.search || ''}
              onChange={(e) => onSearchValueChange(e.target.value)}
              placeholder="Search Products"
              iconLeft={<SearchSVG />}
            />
          </SearchInputWrapperStyled>
          <div style={{ display: 'flex', gap: '15px' }}>
            <Filter
              filterContentChildren={
                <RadioButton
                  options={[
                    { label: 'All', value: 'all' },
                    { label: 'Featured', value: 'featured' },
                    { label: 'Not Featured', value: 'not-featured' },
                  ]}
                  onChange={(e) => handleFeaturedChange(e.target.value)}
                  selctedOption={featuredOption}
                />
              }
            />
            <Button radius="md" onClick={openModal}>
              New Product
            </Button>
          </div>
        </div>

        <Table
          customRow={productTabs}
          tableData={tableData}
          isLoading={loading}
          noData="No data to display."
          showCheckBox={false}
          maxWidthForCardView="768px"
          headingBgColor="#f0f0f0"
          showMenuIcon={false}
          evenRowBgColor="#fafafa"
        />

        <Pagination
          activePage={paginationParams.page || 1}
          onCurrentPageChange={handleCurrentPage}
          onLast={handleLastPage}
          onNext={handleNextPage}
          totalPages={pagination?.totalPages}
          limit={paginationParams.limit || 10}
          onLimitChange={handleLimitChange}
        />
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title="Create New Product"
          halfWidth={true}
        >
          <CreateProducts setIsModalOpen={setIsModalOpen} />
        </Modal>
      )}
    </>
  );
};

export default Ecommerce;
