import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import adminsApi, { paginationType } from '@/apiConfigs/admins';
import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import { Table } from '@/components';
import DeleteModal from '@/components/DeleteModal';
import Pagination from '@/components/Pagination';
import { useRoot } from '@/context/RootProvider';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import { TableDataType } from '@/designComponents/Table';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import { SearchInputWrapperStyled } from '@/pages/Documents/style';
import { RootState, useAppDispatch } from '@/store';
import { getAllAdmins } from '@/store/admins/functions';
import { debounce } from '@/utils/debounce';
import requestAPI from '@/utils/requestAPI';

import AddAdmin from './CreateAdmin';

type Props = {};

const Cities = (_props: Props) => {
  const dispatch = useAppDispatch();
  const [openCreateAdminModal, setOpenCreateAdminModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedAdminId, setSelectedAdminId] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const { auth, clearAuth } = useRoot();

  const [paginationParams, setPaginationParams] = useState<paginationType>({
    page: 1,
    limit: 10,
    search: '',
  });
  const adminsState = useSelector((state: RootState) => state.admins);
  const { admins, pagination } = adminsState;
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    dispatch(getAllAdmins(paginationParams));
  }, [dispatch, paginationParams]);

  const handleDelete = async () => {
    if (!selectedAdminId) return;

    try {
      await requestAPI(adminsApi.deleteAdmin(selectedAdminId));
      if (auth._id === selectedAdminId) {
        clearAuth();
      }
      dispatch(getAllAdmins(paginationParams));
      setIsDeleteModalOpen(false);
      toast.success('Admin Deleted Successfully');
    } catch (error) {
      console.log('Error deleting admin:', error);
      setErrorMessage(error.message);
    }
  };

  const tableData: TableDataType = {
    headings: [
      { key: 'email', label: 'Email' },
      { key: 'date', label: 'Created at' },
      {
        key: 'actions',
        label: '',
      },
    ],
    body: Array.isArray(admins)
      ? admins.map((user) => ({
          id: user._id,
          email: user.email,
          date: moment(user.createdAt).format('MMM DD, YYYY  HH:mm'),
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="danger"
                radius="normal"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setSelectedAdminId(user._id);
                }}
              >
                Delete
              </Button>
            </div>
          ),
        }))
      : [],
  };

  const debouncedSearch = useCallback(
    debounce((term: string) => {
      setPaginationParams((prev) => ({
        ...prev,
        search: term,
        page: 1,
      }));
    }, 300),
    []
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handleCurrentPage = (page: number) => {
    setPaginationParams((prev) => ({ ...prev, page: page }));
  };

  const handleLimitChange = (limit: number) => {
    setPaginationParams((prev) => ({ ...prev, page: 1, limit }));
  };

  const handleNextPage = () => {
    if (
      paginationParams.page &&
      pagination?.totalPages &&
      paginationParams?.page < pagination.totalPages
    ) {
      handleCurrentPage(paginationParams.page + 1);
    }
  };

  const handleLastPage = () => {
    if (
      pagination?.totalPages &&
      paginationParams.page !== pagination.totalPages
    ) {
      handleCurrentPage(pagination.totalPages);
    }
  };

  useHeaderTitle('Admin Users');

  return (
    <div>
      <div style={{ marginBottom: '16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SearchInputWrapperStyled>
            <Input
              value={searchTerm}
              onChange={handleInputChange}
              placeholder="Search Admin"
              iconLeft={<SearchSVG />}
            />
          </SearchInputWrapperStyled>

          {openCreateAdminModal && (
            <AddAdmin
              isOpenModal={openCreateAdminModal}
              onClose={() => setOpenCreateAdminModal(false)}
            />
          )}

          <Button radius="md" onClick={() => setOpenCreateAdminModal(true)}>
            New Admin
          </Button>
        </div>
      </div>

      <Table
        tableData={tableData}
        noData="No data to display."
        showCheckBox={false}
        maxWidthForCardView="768px"
        headingBgColor="#f0f0f0"
        showMenuIcon={false}
        evenRowBgColor="#fafafa"
      />

      <Pagination
        activePage={paginationParams.page || 1}
        onCurrentPageChange={handleCurrentPage}
        totalPages={pagination?.totalPages || 1}
        limit={paginationParams.limit || 10}
        onLimitChange={handleLimitChange}
        onNext={handleNextPage}
        onLast={handleLastPage}
      />

      <DeleteModal
        title="housebuild.com says"
        onClose={() => setIsDeleteModalOpen(false)}
        isOpen={isDeleteModalOpen}
        onConfirmDelete={handleDelete}
        deleteText={`Are you sure you want to delete this admin?`}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default Cities;
