import slugify from 'slugify';

export const showRespectiveHashtag = (
  hashTags: string[],
  hashTagOption: Array<{ label: string; value: string }>
) => {
  const storedHashTags =
    hashTagOption &&
    hashTagOption.reduce(
      (prev: Array<{ label: string; value: string }>, curr) => {
        if (hashTags && hashTags.includes(curr.value)) {
          prev.push(curr);
        }
        return prev;
      },
      [] as Array<{ label: string; value: string }>
    );
  return storedHashTags;
};

export const switchBodyContent = (
  contentType: string,
  body: {
    content: string;
    contentType: string;
  }
) => {
  if (contentType === body?.contentType) {
    return body?.content;
  }
  return null;
};

export const generateSlug = (value: string) => {
  const generatedSlug = slugify(value, {
    replacement: '-',
    lower: true,
    strict: false,
    trim: true,
    remove: /[*+~.()'"!:@]/g,
  });

  return generatedSlug;
};
