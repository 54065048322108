import axiosInstance from './apiHelpers';
import handleLogout from './handleLogout';

export type MethodType =
  | 'get'
  | 'post'
  | 'delete'
  | 'patch'
  | 'put'
  | 'head'
  | 'options';

export type ConfigType = {
  url: string;
  method: MethodType;
  data?: any;
  params?: {
    page?: number;
    limit?: number;
    [key: string]: any;
  };
  config?: {
    showToast?: boolean;
  };
};

const requestAPI = async (config: ConfigType): Promise<any> => {
  try {
    const result = await axiosInstance(config);
    return result?.data?.data || result?.data || result;
  } catch (error: any) {
    // Handle network errors
    if (!error.response) {
      alert('Network Error: Please check your internet connection.');
      throw new Error('Network Error');
    }

    // Handle HTTP errors
    const status = error.response.status;
    let message = '';
    if (status >= 400 && status < 500) {
      message = error.response.data.message || error.message || 'Client Error';
    } else if (status >= 500) {
      message = error.response.data.message || error.message || 'Server Error';
    }

    // Show alert if showToast is true
    if (config?.config?.showToast) {
      alert(message);
    }
    if (status === 403) {
      return handleLogout();
    }
    console.log('FROM REQ ::', message);

    // Rethrow the error
    throw new Error(message || 'On request something went wrong.');
  }
};

export default requestAPI;
