import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PaginationType } from '@/apiConfigs/downloadCenter';
import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import { Table } from '@/components';
import Pagination from '@/components/Pagination';
import { useRoot } from '@/context/RootProvider';
import Button from '@/designComponents/Button';
import { ButtonTab } from '@/designComponents/ButtonTab';
import Input from '@/designComponents/Input';
import {
  DOWNLOAD_CENTER_FOLDER_ROUTE,
  DOWNLOAD_CENTER_RESOURCE_ROUTE,
  DOWNLOAD_CENTER_ROUTE,
  DOWNLOAD_CENTER_TYPE_ROUTE,
} from '@/routes/routePaths';
import { RootState, useAppDispatch, useAppSelector } from '@/store';
import { getAllDownloadCategory } from '@/store/downloadCenter/functions';
import { humanReadableDateWithTime } from '@/utils/date';

import { SearchInputWrapperStyled } from '../Documents/style';
import CreateCategory from './CreateCategory';
import CreateTypes from './createTypes';
import { cardData } from './data';
import DownloadGroup from './DownloadGroup';
import CreateFolders from './DownloadGroup/CreateFolder';
import DownloadResource from './DownloadResource';
import CreateResources from './DownloadResource/CreateResources';
import DownloadType from './DownloadType';

export const debounce = <T extends (...args: any[]) => void>(
  fn: T,
  delay: number
) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  const debouncedFunction = (...args: Parameters<T>) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), delay);
  };

  debouncedFunction.cancel = () => {
    clearTimeout(timeoutId);
  };

  return debouncedFunction;
};

const DownloadCenter = () => {
  const [isLoading, _setIsLoading] = useState(false);
  const { pathname } = useLocation();
  const splitPathname = pathname.split('/');

  const { setHeaderTitle } = useRoot();

  const [cardKey, setCardKey] = useState<string>(
    splitPathname[2] || 'category'
  );
  const [openCreateDocModal, setOpenCreateDocModal] = useState(false);
  const [openCreateResourceModal, setOpenCreateResourceModal] = useState(false);
  const [openCreateCatModal, setOpenCreateCatModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [paginationParams, setPaginationParams] = useState<PaginationType>({
    page: 1,
    limit: 10,
    search: '',
  });

  const categoryState = useAppSelector(
    (state: RootState) => state.downloadCenterCategory
  );

  const { downloadCenterCategory, pagination } = categoryState;

  console.log(pagination, 'page');

  const tableData = {
    headings: [
      { key: 'title', label: 'Title' },
      { key: 'date', label: 'Created At' },
      { key: 'updatedDate', label: 'Updated At' },
      { key: 'actions', label: 'Actions' },
    ],
    body: Array.isArray(downloadCenterCategory)
      ? downloadCenterCategory?.map((category) => {
          return {
            id: category._id,
            title: category.title,
            date: humanReadableDateWithTime(category.createdAt),
            updatedDate: humanReadableDateWithTime(category.updatedAt),
            actions: (
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="gray"
                  radius="normal"
                  onClick={() => navigate(category._id)}
                >
                  View
                </Button>
              </div>
            ),
          };
        })
      : [],
  };

  const fetchDownloadCategories = useCallback(() => {
    dispatch(getAllDownloadCategory(paginationParams));
  }, [dispatch, paginationParams]);

  useEffect(() => {
    fetchDownloadCategories();
  }, [fetchDownloadCategories]);

  const handleCurrentPage = (page: number) => {
    setPaginationParams((prev) => ({ ...prev, page }));
  };

  const handleLimitChange = (limit: number) => {
    setPaginationParams((prev) => ({ ...prev, page: 1, limit }));
  };

  const handleNextPage = (page) => {
    setPaginationParams({ ...paginationParams, page: page });
  };

  const handleLastPage = (page: number) => {
    setPaginationParams({ ...paginationParams, page });
  };

  const debouncedSearch = useCallback(
    debounce((term: string) => {
      setPaginationParams((prev) => ({
        ...prev,
        search: term,
        page: 1,
      }));
    }, 300),
    []
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handleClick = (key: string) => {
    setCardKey(key);
    if (key === 'category') {
      navigate(DOWNLOAD_CENTER_ROUTE);
    } else if (key === 'folders') {
      navigate(`${DOWNLOAD_CENTER_ROUTE}/${DOWNLOAD_CENTER_FOLDER_ROUTE}`);
    } else if (key === 'resources') {
      navigate(`${DOWNLOAD_CENTER_ROUTE}/${DOWNLOAD_CENTER_RESOURCE_ROUTE}`);
    } else if (key === 'types') {
      navigate(`${DOWNLOAD_CENTER_ROUTE}/${DOWNLOAD_CENTER_TYPE_ROUTE}`);
    }
  };

  const downloadCenterTabs = (
    <ButtonTab
      items={cardData}
      activeIndex={cardData.findIndex((x) => x.key === cardKey)}
      setActiveIndex={(index) => handleClick(cardData[index].key)}
    />
  );

  useEffect(() => {
    setHeaderTitle('Download Center');
    return () => {
      setHeaderTitle(null);
    };
  }, [setHeaderTitle]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <SearchInputWrapperStyled>
              <Input
                value={searchTerm}
                onChange={handleInputChange}
                placeholder={
                  cardKey === 'category'
                    ? 'Search Download Category'
                    : cardKey === 'folders'
                      ? 'Search folders'
                      : cardKey === 'resources'
                        ? 'Search Resources'
                        : null
                }
                iconLeft={<SearchSVG />}
              />
            </SearchInputWrapperStyled>
          </div>
          <div>
            {cardKey === 'folders' && (
              <Button radius="md" onClick={() => setOpenCreateDocModal(true)}>
                New Folder
              </Button>
            )}
            {openCreateDocModal && (
              <CreateFolders
                isOpenModal={openCreateDocModal}
                onClose={() => setOpenCreateDocModal(false)}
              />
            )}
            {openCreateResourceModal && (
              <CreateResources
                isOpenModal={openCreateResourceModal}
                onClose={() => setOpenCreateResourceModal(false)}
              />
            )}
            {cardKey === 'category' && (
              <Button radius="md" onClick={() => setOpenCreateCatModal(true)}>
                New Category
              </Button>
            )}
            {openCreateCatModal && (
              <CreateCategory
                isOpenModal={openCreateCatModal}
                onClose={() => setOpenCreateCatModal(false)}
              />
            )}
            {cardKey === 'types' && <CreateTypes />}
            {cardKey === 'resources' && (
              <Button
                radius="md"
                onClick={() => setOpenCreateResourceModal(true)}
              >
                New Resources
              </Button>
            )}
          </div>
        </div>

        {cardKey === 'folders' ? (
          <DownloadGroup
            downloadCenterTabs={downloadCenterTabs}
            search={searchTerm}
          />
        ) : cardKey === 'resources' ? (
          <DownloadResource
            downloadCenterTabs={downloadCenterTabs}
            search={searchTerm}
          />
        ) : cardKey === 'types' ? (
          <DownloadType />
        ) : (
          <>
            <Table
              tableData={tableData}
              customRow={downloadCenterTabs}
              isLoading={isLoading}
              noData="No data to display."
              headingBgColor="#f0f0f0"
              showMenuIcon={false}
              evenRowBgColor="#fafafa"
            />
            <Pagination
              activePage={paginationParams.page || 1}
              onCurrentPageChange={handleCurrentPage}
              onLast={handleLastPage}
              onNext={handleNextPage}
              totalPages={pagination?.totalPages}
              limit={paginationParams.limit || 10}
              onLimitChange={handleLimitChange}
            />
          </>
        )}
      </div>
    </>
  );
};

export default DownloadCenter;
