export type InitialValuesType = {
  documentTitle: string;
  documentType: string;
  parentTitle: string;
  icon: File[] | null;
  type: string;
  jurisdictions: string[];
};
export const cardData = [
  {
    title: 'Category',
    key: 'category',
    // count: 492,
  },
  {
    title: 'Folders',
    key: 'folders',
    // count: 492,
  },
  {
    title: 'Resources',
    key: 'resources',
    // count: 492,
  },
];
