import React from 'react';

import Select from '@/designComponents/Select';
import Typography from '@/designComponents/Typography';

import { PaginationWrapper } from './style';

type Props = {
  limit?: number;
  onCurrentPageChange: (page: number) => void;
  activePage: number;
  totalPages: number;
  onLimitChange?: (limit: number) => void;
};

const options = [
  { label: '10 Rows', value: 10 },
  { label: '20 Rows', value: 20 },
];

const PageButton = ({
  page,
  isActive,
  onClick,
}: {
  page: number;
  isActive: boolean;
  onClick: (page: number) => void;
}) => (
  <button
    onClick={() => onClick(page)}
    className={isActive ? 'active-page' : 'button'}
  >
    {page}
  </button>
);

const RowsSelector = ({
  limit,
  onLimitChange,
}: {
  limit: number;
  onLimitChange?: (limit: number) => void;
}) => {
  const handleSelect = (value: string | number) => {
    if (typeof value === 'number' && onLimitChange) {
      onLimitChange(value);
    }
  };

  return (
    <div className="row-show">
      <Typography
        fontFamily="roboto"
        size="normal"
        fontWeight="normal"
        color="dark.neutral_450"
      >
        Showing
      </Typography>
      <Select
        options={options}
        placeholder=""
        onSelect={handleSelect}
        selected={limit}
        isReduceIconGap
      />
    </div>
  );
};

const Pagination = ({
  limit = 10,
  onCurrentPageChange,
  totalPages,
  activePage,
  onLimitChange,
}: Props) => {
  const pageNumbers = [];

  for (let i = activePage - 1; i <= activePage + 1; i++) {
    if (i < 1) continue;
    if (i > totalPages) break;
    pageNumbers.push(i);
  }

  const handlePageChange = (newPage: number) => {
    onCurrentPageChange(newPage);
  };

  return (
    <PaginationWrapper>
      <RowsSelector limit={limit} onLimitChange={onLimitChange} />
      <div className="pages">
        <button
          className="button"
          onClick={() => handlePageChange(1)}
          disabled={activePage === 1}
          style={{ cursor: activePage === 1 ? 'not-allowed' : 'pointer' }}
        >
          {'<<'} First
        </button>
        <button
          className="button"
          onClick={() => handlePageChange(Math.max(activePage - 1, 1))}
          disabled={activePage === 1}
          style={{ cursor: activePage === 1 ? 'not-allowed' : 'pointer' }}
        >
          {'<'} Prev
        </button>

        {pageNumbers.map((page) => (
          <PageButton
            key={page}
            page={page}
            isActive={activePage === page}
            onClick={handlePageChange}
          />
        ))}

        <button
          className="button"
          onClick={() => handlePageChange(Math.min(activePage + 1, totalPages))}
          disabled={activePage === totalPages}
          style={{
            cursor: activePage === totalPages ? 'not-allowed' : 'pointer',
          }}
        >
          Next {'>'}
        </button>
        <button
          className="button"
          onClick={() => handlePageChange(totalPages)}
          disabled={activePage === totalPages}
          style={{
            cursor: activePage === totalPages ? 'not-allowed' : 'pointer',
          }}
        >
          Last {'>>'}
        </button>
      </div>
      <Typography
        fontFamily="roboto"
        size="normal"
        fontWeight="normal"
        color="dark.neutral_450"
      >
        Total pages: {totalPages}
      </Typography>
    </PaginationWrapper>
  );
};

export default Pagination;
