import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import JobPosts from '@/apiConfigs/jobs';
import BellIcon from '@/assets/bellIcon.svg';
import Logo from '@/assets/icons/user.svg';
import Button from '@/designComponents/Button';
import Select from '@/designComponents/Select';
import Typography from '@/designComponents/Typography';
import requestAPI from '@/utils/requestAPI';

import { JobsContainerStyled } from '../style';

type Props = {};

const JobPosting = (_props: Props) => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);

  const handleGetJobs = async () => {
    try {
      const data = await requestAPI(JobPosts.getAllJobs());
      setJobs(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(jobs, 'jobs');

  useEffect(() => {
    handleGetJobs();
  }, []);
  const dropDownOption: Array<{
    value: string | number;
    label: string;
  }> = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Recent',
      value: 'recent',
    },
  ];
  return (
    <div
      style={{
        border: '1px solid #E8E8E8',
        borderRadius: '7px',
        padding: '15px',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', gap: '7px' }}>
          <img src={BellIcon} alt="bell icon" />
          <Typography size="title" fontWeight="medium">
            Job Posting Approval Notifications
          </Typography>
        </div>
        {/* <Select
          options={dropDownOption}
          name="stories"
          placeholder="Select"
          isHideBorder
          isReduceIconGap
        /> */}
      </div>
      {jobs?.slice(0, 2)?.map((job) => {
        return (
          <JobsContainerStyled
            style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}
            key={job?._id}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '19px',
              }}
            >
              <Typography size="highlight" fontWeight="medium">
                {job?.title}
              </Typography>
              <div className="job">
                <img src={Logo} />
                <Typography>Leslie Alexander</Typography>
              </div>
              <div className="job">
                <Typography>Location:</Typography>
                <Typography>{job?.city}</Typography>
              </div>
              <div className="job">
                <Typography>Expected Budget:</Typography>
                <Typography>€{job?.budget}</Typography>
              </div>
              <Button
                radius="normal"
                className="btn"
                onClick={() => navigate(`/jobs/${job?._id}`)}
              >
                View
              </Button>
            </div>
          </JobsContainerStyled>
        );
      })}
    </div>
  );
};

export default JobPosting;
